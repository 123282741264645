import "./App.css";
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { f7, NavRight, Block, Page, Navbar, Searchbar, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, Stepper, Card, CardHeader, CardContent, CardFooter, BlockTitle, List, ListInput, ListItem, Toggle, Progressbar } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, Layer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, Title, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import styled from '@emotion/styled';
import { Image } from './Components';
import { calc, Debug } from './layout';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { 
    resetState,
    getLayoutInfo,
    getColors
} from "./redux/actions";


function LayoutPage(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    const productInfo = stateObj.productInfo;
    const selectedPalette = stateObj.selectedPalette;
    const selectedFonts = stateObj.selectedFonts;
    const selectedPaper = stateObj.selectedPaper;
    const title = stateObj.title;
    const subtitle = stateObj.subtitle;
    const author = stateObj.author;
    const pageCount = stateObj.pageCount;
    const [error, setError] = useState([]);
    const errorMessage = stateObj.errorMessage;
    const swiperEl = useRef(null);
    const KINDLE = 4;
    const PAPERBACK = 5;

    useEffect(()=>{
        dispatch(resetState({errorMessage:null}));
        dispatch(getLayoutInfo(props.sku));
    },[])

    //console.log(productInfo);

    const handleColors = ()=>{
        dispatch(resetState({errorMessage:null}));
        f7.views.main.router.navigate("/colors/");
    }

    const handleFonts = ()=>{
        dispatch(resetState({errorMessage:null}));
        f7.views.main.router.navigate("/fonts/");
    }

    const handlePapers = ()=>{
        dispatch(resetState({errorMessage:null}));
        f7.views.main.router.navigate("/papers/");
    }

    const handleGenerate = ()=>{
        dispatch(resetState({errorMessage:null}));
        let e = [...error];
        const ERR1 = "Number of pages is required";
        const ERR2 = "Select color palette";
        const ERR3 = "Select fonts";
        const ERR4 = "Select paper";
        const ERR5 = "Title is requred";
        const ERR6 = "Subtitle is requred";
        const ERR7 = "Author is requred";
        let num = "";
        if (productInfo.book_type !== KINDLE) {
            num = $("li.data_page_number").find("input").val().trim();
            if (num !== "") {
                num = parseInt(num);
                e = _.filter(e, x => x !== ERR1);
            } else {
                e.push(ERR1);
            }
        }
        let title = $("li.data_title").find("input").val().trim();
        let subtitle = $("li.data_subtitle").find("input").val().trim();
        let author = $("li.data_author").find("input").val().trim();
        if (!title) {
            e.push(ERR5);
        } else {
            e = _.filter(e, x => x !== ERR5);
        }
        if (!subtitle) {
            e.push(ERR6);
        } else {
            e = _.filter(e, x => x !== ERR6);
        }
        if (!author) {
            e.push(ERR7);
        } else {
            e = _.filter(e, x => x !== ERR7);
        }
        if (selectedPalette.sku === "") {
            e.push(ERR2);
        } else {
            e = _.filter(e, x => x !== ERR2);
        }
        if (selectedFonts.sku === "") {
            e.push(ERR3);
        } else {
            e = _.filter(e, x => x !== ERR3);
        }
        if (selectedPaper.sku === "") {
            e.push(ERR4);
        } else {
            e = _.filter(e, x => x !== ERR4);
        }
        e = _.uniq(e);
        setError(e);
        if (_.isEmpty(e)) {
            let book_type = productInfo.book_type;
            /*_.forEach(productInfo.categories, (x) => {
                if (x.name === "Paperbacks") {
                    category = x.name;
                    return false;
                } else if (x.name === "Ebooks") {
                    category = x.name;
                    return false;
                } else if (x.name === "Hardcovers") {
                    category = x.name;
                    return false;
                }
            })*/
            let data = {
                indesign_file: productInfo.indesign_file, 
                book_type: book_type,
                layout_name:productInfo.name, 
                palette: [selectedPalette.color1, selectedPalette.color2, selectedPalette.color3],
                fonts: [selectedFonts.font1, selectedFonts.font2, selectedFonts.font3],
                paper_thickness: parseFloat(selectedPaper.paper_thickness),
                paper_sku: selectedPaper.sku,                
                num_pages:parseInt(num),
                title:title,
                subtitle:subtitle,
                author:author
            };
            window.host().postMessage({command:"book_layout", data:data});
            console.log(data);
        }
    }

    const handleChangeTitle = (e)=>{
        let value = e.target.value;
        dispatch(resetState({title:value}));
    }

    const handleChangeSubtitle = (e)=>{
        let value = e.target.value;
        dispatch(resetState({subtitle:value}));
    }

    const handleChangeAuthor = (e)=>{
        let value = e.target.value;
        dispatch(resetState({author:value}));
    }

    const handleChangePageCount = (e)=>{
        let value = e.target.value;
        dispatch(resetState({pageCount:parseInt(value)}));
    }

    let leftCol = lt.bunch4;
    let rightCol = lt.bunch4;
    if (lt.max > 8) {
        rightCol = lt.bunch8;
    }
    

    return (
        <Page dark>
            <Navbar dark outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Layout</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
            {/*<Debug lt={lt}></Debug>*/}
            { lt.max === 4 && productInfo &&
                <Column top={16} left={lt.margin} right={lt.margin} >
                    <Column width={leftCol-lt.margin/2} height={leftCol-lt.margin/2} self={Axis.self.center}>
                        <swiper-wrapper>
                            <Swiper pagination navigation loop observer observeSlideChildren ref={swiperEl}>
                                { productInfo && productInfo.media_gallery.map((media)=>{
                                    return(
                                        <SwiperSlide key={uuid()}>
                                            <Column sx={{marginTop:5, marginBottom:5}}><Image className="border" src={media.url} width={leftCol-lt.margin/2} height={leftCol-lt.margin/2} fit="contain"></Image></Column>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </swiper-wrapper>
                        
                    </Column>
                    <Row top={16} bottom={16} main={Axis.main.center}><Header2>{productInfo.name}</Header2></Row>
                    <Column>
                        <BlockTitle>Data</BlockTitle>                        
                        <List strongIos dividersIos>
                            { productInfo.book_type !== KINDLE &&
                            <ListInput className="data_page_number" outline label="Number of pages" floatingLabel type="number" onChange={handleChangePageCount} value={pageCount ? pageCount : ""}/>
                            }
                            <ListInput className="data_title" outline label="Title" floatingLabel type="text" onChange={handleChangeTitle} value={title}></ListInput>
                            <ListInput className="data_subtitle" outline label="Subtitle" floatingLabel type="text" onChange={handleChangeSubtitle} value={subtitle}></ListInput>
                            <ListInput className="data_author" outline label="Author" floatingLabel type="text" onChange={handleChangeAuthor} value={author}></ListInput>
                        </List>
                        <BlockTitle>Parameters</BlockTitle>
                        <List dividersIos outlineIos strongIos>
                            <ListItem title={selectedPalette.sku === "" ? "Color": selectedPalette.name} link="#" onClick={handleColors}/>
                            <ListItem title={selectedFonts.sku === "" ? "Font": selectedFonts.name} link="#" onClick={handleFonts}/>
                            <ListItem title={selectedPaper.sku === "" ? "Paper": selectedPaper.name} link="#" onClick={handlePapers}/>
                        </List>
                        
                        { error.map((e, i)=>{
                            return(
                                <Row key={e+i.toString()}><ErrorMessage>{e}</ErrorMessage></Row>
                            )
                        })}
                        { errorMessage &&
                            <Row><ErrorMessage>{errorMessage}</ErrorMessage></Row>
                        }
                        <Column top={20} bottom={100}><Button fill large text="Generate Book Cover Layout" onClick={handleGenerate}></Button></Column>
                    </Column>
                </Column>
            }
            { lt.max >= 8 && productInfo &&
                <Row top={16} left={lt.margin} right={lt.margin}>
                    <Column width={leftCol} height={leftCol}>
                        <Image src={productInfo.image.url} width="100%" height="100%" fit="contain"></Image>
                    </Column>
                    <Column width={rightCol} left={lt.gutter}>
                        <Row top={0} bottom={16} left={lt.margin}><Header2>{productInfo.name}</Header2></Row>
                        <Column>
                            <BlockTitle>Data</BlockTitle>
                            <List strongIos dividersIos>
                                { productInfo.book_type !== KINDLE &&
                                <ListInput className="data_page_number" outline label="Number of pages" floatingLabel type="number"/>
                                }
                                <ListInput className="data_title" outline label="Title" floatingLabel type="text" ></ListInput>
                                <ListInput className="data_subtitle" outline label="Subtitle" floatingLabel type="text" ></ListInput>
                                <ListInput className="data_author" outline label="Author" floatingLabel type="text" ></ListInput>
                            </List>
                            <BlockTitle>Parameters</BlockTitle>
                            <List dividersIos outlineIos strongIos>
                                <ListItem title={selectedPalette.sku === "" ? "Color": selectedPalette.name} link="#" onClick={handleColors}/>
                                <ListItem title={selectedFonts.sku === "" ? "Font": selectedFonts.name} link="#" onClick={handleFonts}/>
                                <ListItem title={selectedPaper.sku === "" ? "Paper": selectedPaper.name} link="#" onClick={handlePapers}/>
                            </List>
                            { error.map((e, i)=>{
                                return(
                                    <Row key={e+i.toString()}><ErrorMessage>{e}</ErrorMessage></Row>
                                )
                            })}
                            { errorMessage &&
                                <Row><ErrorMessage>{errorMessage}</ErrorMessage></Row>
                            }
                            <Column top={20} bottom={100}><Button fill large text="Generate Book Cover Layout" onClick={handleGenerate}></Button></Column>
                        </Column>
                    </Column>
                </Row>
            }
        </Page>
    );
}

export const Container = styled.div(props => ({
    width: props.width,
    display:"flex",
    flexFlow:"row wrap",
    gap: props.gap, 
    //boxShadow: "0px 0px 0px 1px green inset",
    justifyContent:"center",
    alignItems:"start"
}));



export default LayoutPage;
