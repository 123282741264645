import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { f7, NavRight, Block, Page, Navbar, Searchbar, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, Stepper, Card, CardHeader, CardContent, CardFooter, BlockTitle, List, ListInput, ListItem, Toggle, Progressbar } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, Layer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, Title, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import styled from '@emotion/styled';
import { Image } from './Components';
import { calc, Debug } from './layout';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


import { 
    resetState,
    isTokenValid,
    isTokenValid2,
    createCustomer,
    generateCustomerToken,
    isEmailAvailable,
    requestPasswordResetEmail,
} from "./redux/actions";


function AccountPage(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    const SHOW_LOGIN_FORM = 0;
    const SHOW_FORGOT_PASSWORD_FORM = 1;
    const SHOW_CREATE_ACCOUNT_FORM = 2;
    const SHOW_SUCCESS_FORGOT_PASSWORD_FORM = 3;
    const SHOW_ACCOUNT_PAGE = 4;
    const SHOW_LOADING_PAGE = 5;
    const [formState, setFormState] = useState(SHOW_LOADING_PAGE);
    const [errMessage, setErrMessage] = useState("");
    const [progress, setProgress] = useState(false);
    const refLogin = useRef(null);
    const refPassword = useRef(null);
    const refForgotLogin = useRef(null);
    const refCreateAccountLogin = useRef(null);
    const refCreateAccountPassword = useRef(null);
    const refCreateAccountFirstName = useRef(null);
    const refCreateAccountLastName = useRef(null);
    const refNewPassword = useRef(null);
    const refChooseNewPasswordFormLogin = useRef(null);


    useEffect(()=>{
        stateObj.getCustomerToken().then((currentToken)=>{
            console.log(currentToken);
            if (currentToken) {
                dispatch(isTokenValid2(currentToken, (valid)=>{
                    if (valid) {
                        setTimeout(()=>{
                            setFormState(SHOW_ACCOUNT_PAGE);
                        },1000)
                    } else {
                        setFormState(SHOW_LOGIN_FORM);
                    }
                }))
                /*dispatch(isTokenValid(currentToken, (valid)=>{
                    if (!valid) {
                        stateObj.getCustomerData().then((data)=>{
                            if (data !== null) {
                                console.log(data);
                                let emailText = data.email;
                                let passwordText = data.password;
                                dispatch(isEmailAvailable(emailText, (result)=>{
                                    if (!result) {
                                        dispatch(generateCustomerToken(emailText, passwordText, (data)=>{
                                            if (data.hasOwnProperty("error")) {
                                                console.log(data.error);
                                                window.host().postMessage({command:"set_customer_token", data:null});
                                                window.host().postMessage({command:"set_customer_data", data:null});
                                                setFormState(SHOW_LOGIN_FORM);
                                            } else {
                                                window.host().postMessage({command:"set_customer_token", data:data.token});
                                                window.host().postMessage({command:"set_customer_data", data:JSON.stringify({"email":emailText, "password":passwordText})});
                                                setFormState(SHOW_ACCOUNT_PAGE);
                                            }
                                        }));    
                                    } else {
                                        window.host().postMessage({command:"set_customer_token", data:null});
                                        window.host().postMessage({command:"set_customer_data", data:null});
                                        setFormState(SHOW_LOGIN_FORM);
                                    }
                                }));
                            } else {
                                window.host().postMessage({command:"set_customer_token", data:null});
                                window.host().postMessage({command:"set_customer_data", data:null});
                                setFormState(SHOW_LOGIN_FORM);
                            }
                        })
                        window.host().postMessage({command:"set_customer_token", data:null});
                        window.host().postMessage({command:"set_customer_data", data:null});
                        setFormState(SHOW_LOGIN_FORM);
                    } else {
                        setFormState(SHOW_ACCOUNT_PAGE);
                    }
                }))*/
            } else {
                //window.host().postMessage({command:"set_customer_token", data:null});
                //window.host().postMessage({command:"set_customer_data", data:null});
                setFormState(SHOW_LOGIN_FORM);
            }
        });

        /*stateObj.getCustomerData().then((data)=>{
            console.log(data);
        })*/
    },[])

    useLayoutEffect(()=>{
        $(".item-input-wrap").css({color:"white"});
    },[])

    const handleSignIn = () => {
        let emailText = $(document).find(".login-email").find("input").val();
        let passwordText = $(document).find(".login-password").find("input").val();
        //console.log(emailText, passwordText);
        if (!emailText) {
            setErrMessage("Please provide an e-mail.");
            return;
        } else {
            const regex = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
            if (regex.test(emailText) === false) {
                setErrMessage("You have entered an invalid e-mail address. Please try again.");
                return;
            }
        }
        if (!passwordText) {
            setErrMessage("Please provide a password.");
            return;
        } else {
            if (passwordText.length < 6) {
                setErrMessage("Password must contain at least 8 symbols.");
                return;
            }
        }
        setErrMessage("");
        setProgress(true);
        dispatch(isEmailAvailable(emailText, (result)=>{
            if (!result) {
                dispatch(generateCustomerToken(emailText, passwordText, (data)=>{
                    setProgress(false);
                    if (data.hasOwnProperty("error")) {
                        console.log(data.error);
                        setErrMessage("Please enter a correct e-mail address and password. Note that both fields may be case-sensitive.");
                        return;
                    } else {
                        window.host().postMessage({command:"set_customer_token", data:data.token});
                        window.host().postMessage({command:"set_customer_data", data:JSON.stringify({"email":emailText, "password":passwordText})});
                        setFormState(SHOW_ACCOUNT_PAGE);
                    }
                }));    
            } else {
                setProgress(false);
                setErrMessage("The e-mail address you have entered doesn't match any account. Sign up for an account.");
                return;
            }
        }));
    }

    const getToken = async () => {
        let token = await stateObj.getCustomerToken();
        console.log("here555: " + token);
    }

    const handleShowCreateAccountForm = () => {
        setErrMessage("")
        setFormState(SHOW_CREATE_ACCOUNT_FORM);
    }

    const handleLogOut = (e) => {
        setErrMessage("")
        window.host().postMessage({command:"set_customer_token", data:null});
        window.host().postMessage({command:"set_customer_data", data:null});
        //localStorage.removeItem('customer_token');
        //dispatch(resetState({customerEmail:null, customerPassword:null, authorized:false}));
        setFormState(SHOW_LOGIN_FORM);
    }

    const handleForgotPassword = () => {
        setErrMessage("")
        setFormState(SHOW_FORGOT_PASSWORD_FORM);
    }

    const handleCreateAccount = () => {
        const emailText = $(".create-account-email").find("input").val();
        const passwordText = $(".create-account-password").find("input").val();
        const firstNameText = $(".create-account-first-name").find("input").val();
        const lastNameText = $(".create-account-last-name").find("input").val();
        if (!emailText) {
            setErrMessage("Please provide an e-mail.");
            return;
        } else {
            const regex = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
            if (regex.test(emailText) === false) {
                setErrMessage("You have entered an invalid e-mail address. Please try again.");
                return;
            }
        }
        if (!passwordText) {
            setErrMessage("Please provide a password.");
            return;
        } else {
            if (passwordText.length < 6) {
                setErrMessage("Password must contain at least 8 symbols.");
                return;
            }
        }
        if (!firstNameText) {
            setErrMessage("Please provide a first name.");
            return;
        } 
        if (!lastNameText) {
            setErrMessage("Please provide a last name.");
            return;
        } 
        setErrMessage("");
        setProgress(true);
        dispatch(createCustomer(emailText, passwordText, firstNameText, lastNameText, (data)=>{
            setProgress(false);
            if (data.hasOwnProperty("error")) {
                setErrMessage(data.error);
                return;
            } else {
                /*refCreateAccountLogin.current.value = "";
                refCreateAccountPassword.current.value = "";
                refCreateAccountFirstName.current.value = "";
                refCreateAccountLastName.current.value = "";
                
                refLogin.current.value = emailText;
                refPassword.current.value = passwordText;*/
                setErrMessage("")
                setFormState(SHOW_LOGIN_FORM);
                return;
            }
        }));
    }
    const handleShowLoginForm = () => {
        setErrMessage("")
        setFormState(SHOW_LOGIN_FORM);
    }

    const handleRetrivePassword = (e) => {
        const emailText = $(document).find(".forgot-password-email").find("input").val();
        if (!emailText) {
            setErrMessage("Please provide an e-mail.");
            return;
        } else {
            const regex = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
            if (regex.test(emailText) === false) {
                setErrMessage("You have entered an invalid e-mail address. Please try again.");
                return;
            }
            setProgress(true);
            dispatch(isEmailAvailable(emailText, (result)=>{
                if (!result) {
                    dispatch(requestPasswordResetEmail(emailText, (data)=>{
                        setProgress(false);
                        if (data.hasOwnProperty("error")) {
                            setErrMessage(data.error);
                        } else {
                            //refForgotLogin.current.value = "";
                            setErrMessage("");
                            //removeCookie("customer_token");
                            //dispatch(resetState({customerEmail:null, customerPassword:null, authorized:false}));                
                            setFormState(SHOW_SUCCESS_FORGOT_PASSWORD_FORM);
                        }
                        console.log(data);
                    }));
                } else {
                    setProgress(false);
                    setErrMessage("The e-mail address you have entered doesn't match any account.");
                    return;
                }
            }))
            
        }
        setErrMessage("");
    }
    const handlePrivacyPolicy = () => {
        props.f7router.navigate("/privacy/");
    }

    const handleTems = () => {
        props.f7router.navigate("/terms/");
    }

    let colWidth = lt.bunch4;
    if (lt.max >= 8) {
        colWidth = lt.bunch8;
    }

    return (
        <Page id="home-page" dark >
            <Navbar dark outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Account</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
            <Column center>
                <Column width={lt.containerWidth-lt.margin} center>
                    <Column width={colWidth} >
                        <Column left={lt.margin} right={lt.margin} sx={{display: formState === SHOW_LOADING_PAGE ? "flex": "none", minHeight: "calc(100vh - 44px)"}} cross={Axis.cross.center}>
                            <Row height={40}></Row>
                            <Player autoplay loop src="/js/animation_llrzmj1u.json" style={{ height: '200px', width: '200px' }}></Player>
                        </Column>
                        <Column sx={{display: formState === SHOW_LOGIN_FORM ? "flex": "none", minHeight: "calc(100vh - 44px)"}}>
                            <Row self={Axis.self.start}><h1>Sign in</h1></Row>
                            <List strongIos dividersIos>
                                <ListInput className='login-email' outline label="Email" floatingLabel type="email" validate></ListInput>
                                <ListInput className='login-password' outline label="Password" floatingLabel type="password"></ListInput>
                            </List>
                            <Row top={errMessage ? 20: 0} bottom={errMessage ? 20: 0}><ErrorMessage>{errMessage}</ErrorMessage></Row>
                            <Column top={0} width='100%'><Button fill onClick={handleSignIn} preloader loading={progress} text='Sign in'></Button></Column>
                            <Row top={60} main={Axis.main.between} cross={Axis.cross.center}>
                                <Subtitle1>Don't have an account yet?</Subtitle1>   
                                <Row left={8}><Button outlineIos smallIos onClick={handleShowCreateAccountForm}>Sign up</Button></Row>
                            </Row>
                            <Row top={16} bottom={40} main={Axis.main.between} cross={Axis.cross.center}>
                                <Subtitle1>Forgot password?</Subtitle1>   
                                <Row left={8}><Button outlineIos smallIos onClick={handleForgotPassword}>Restore</Button></Row>
                            </Row>
                        </Column>
                        <Column sx={{display: formState === SHOW_CREATE_ACCOUNT_FORM ? "flex": "none", minHeight: "calc(100vh - 44px)"}}>
                            <Row self={Axis.self.start}><h1>Create an account</h1></Row>
                            <List strongIos dividersIos>
                                <ListInput className='create-account-email' outline label="E-mail" floatingLabel type="email" required validate>
                                </ListInput>
                                <ListInput className='create-account-first-name' outline label="First name" floatingLabel type="text" required validate>
                                </ListInput>
                                <ListInput className='create-account-last-name' outline label="Last name" floatingLabel type="text" required validate>
                                </ListInput>
                                <ListInput className='create-account-password' outline label="Password" floatingLabel type="password" required validate>
                                </ListInput>
                            </List>
                            <Row top={errMessage ? 20: 0} bottom={errMessage ? 20: 0}><ErrorMessage>{errMessage}</ErrorMessage></Row>
                            <Row center><Column width='100%'><Button fill onClick={handleCreateAccount} preloader loading={progress} text='Sign Up'></Button></Column></Row>
                            <Row top={8}><p>By clicking Sign Up, you agree to our Terms of Service and you have read our Privacy Policy</p></Row>
                            <Row top={60} bottom={40} main={Axis.main.between} cross={Axis.cross.center}>
                                <Subtitle1>Already a member?</Subtitle1>
                                <Row left={8}><Button outlineIos smallIos onClick={handleShowLoginForm}>Sign in</Button></Row>
                            </Row>
                        </Column>
                        <Column sx={{display: formState === SHOW_ACCOUNT_PAGE ? "flex": "none", minHeight: "calc(100vh - 44px)"}}>
                            <Row height={40}></Row>
                            <Player autoplay loop src="/js/lottie_success.json" style={{ height: '200px', width: '200px' }}></Player>
                            <Column top={40} width={100} self={Axis.self.center}><Button tonal onClick={handleLogOut} preloader loading={progress} text='Sign out'></Button></Column>
                            <AccountInfo></AccountInfo>
                        </Column>
                        <Column sx={{display: formState === SHOW_FORGOT_PASSWORD_FORM ? "flex": "none", minHeight: "calc(100vh - 44px)"}}>
                            <Row self={Axis.self.start}><h1>Forgot password</h1></Row>
                            <List strongIos dividersIos>
                                <ListInput className='forgot-password-email' outline label="E-mail" floatingLabel type="email" required validate>
                                </ListInput>
                            </List>
                            <Row top={errMessage ? 20: 0} bottom={errMessage ? 20: 0}><ErrorMessage>{errMessage}</ErrorMessage></Row>
                            <Row top={0} center><Column width='100%'><Button fill onClick={handleRetrivePassword} preloader loading={progress} text='Retrieve reset link'></Button></Column></Row>
                            <Row top={40} center><Button onClick={handleShowLoginForm}>Back to login page</Button></Row>
                        </Column>
                        <Column left={lt.margin} right={lt.margin} sx={{display: formState === SHOW_SUCCESS_FORGOT_PASSWORD_FORM ? "flex": "none", minHeight: "calc(100vh - 44px)"}}>
                            <Row height={40}></Row>
                            <Player autoplay loop src="/js/animation_llidgsc5.json" style={{ height: '200px', width: '200px' }}></Player>
                            <Row center><h2>Check email for reset link</h2></Row>
                            <Row top={20} center>
                                <Column width={200}>
                                    <Button onClick={handleShowLoginForm} preloader loading={progress} text='Back to login page'></Button>
                                </Column>
                            </Row>
                        </Column>
                        <div style={{display:"flex", flex:1}}></div>
                        <Row top={-44} center>
                            <Row>
                                <Link onClick={handlePrivacyPolicy}><Body2>Privacy Policy</Body2></Link>
                                <Row left={7} right={7}><Body1>·</Body1></Row>
                                <Link onClick={handleTems}><Body2>Terms of Service</Body2></Link>
                            </Row>
                        </Row>
                    </Column>
                </Column>
            </Column>
        </Page>
    );
}

function AccountInfo() {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;

    useEffect(()=>{
        
    },[]);

    return(
        <></>
    )
}


export default AccountPage;
