import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { f7, NavRight, Subnavbar, Block, Page, Navbar, Searchbar, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, Stepper, Card, CardHeader, CardContent, CardFooter, BlockTitle, List, ListInput, ListItem, Toggle, Progressbar } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, Layer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, Title } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import styled from '@emotion/styled';
import { Image } from './Components';
import { calc, Debug } from './layout';
import Pagination from '@mui/material/Pagination';
import "./Commands";
import { Buffer } from "buffer";
import CardMedia from '@mui/material/CardMedia';
//window.Buffer = window.Buffer || require("buffer").Buffer; 


import { 
    updateCalculatedLayout,
    resetState,
    getLayouts
} from "./redux/actions";


function Home(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const [msg, setMsg] = useState(null);
    const layouts = stateObj.layouts;
    const $ = window.$;
    const layoutPage = stateObj.layoutPage;
    const layoutSearchTerm = stateObj.layoutSearchTerm;
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');
    const allCommands = stateObj.allCommands;
    const [filteredCommands, setFilteredCommands] = useState(allCommands);
    const navBarLayoutsVisible = stateObj.navBarLayoutsVisible;
    const navBarCommandsVisible = stateObj.navBarCommandsVisible;
    const navBarTutorialsVisible = stateObj.navBarTutorialsVisible;
    const [navBarTitle, setNavBarTitle] = useState("Book Cover Layouts");

    //console.log(layouts);

    useEffect(()=>{
        if (layoutPage === 0) {
            dispatch(resetState({layoutPage:1}));
            dispatch(getLayouts(1, ""));
        } else {
            //let searchbar = $('.searchbar').get(0).f7Searchbar;
            $($('.searchbar').get(0)).find("input").val(layoutSearchTerm);
            dispatch(getLayouts(layoutPage, layoutSearchTerm));
        }
        
        window.addEventListener("message", (event) => {
            try {
                let msg = event.data;
                window.host = ()=>{ return event.source };
                if (msg.command === "hello") {
                    setMsg(event.data.command);
                    event.source.postMessage({command:"hello", data:"hello"});
                } 
                if (msg.command === "error") {
                    dispatch(resetState({errorMessage:msg.data}));
                }
                if (msg.command === "current_customer_token") {
                    if (msg.data !== null) {
                        let uint8array = [];
                        if (msg.data.token !== null) {
                            _.forEach(Object.keys(msg.data.token), (key)=>{
                                uint8array.push(msg.data.token[key]);
                            });
                            let token = Buffer.from(uint8array).toString();
                            window.receivedCustomerToken(token);
                        } else {
                            window.receivedCustomerToken(null);
                        }
                    } else {
                        window.receivedCustomerToken(null);
                    }
                }
                if (msg.command === "current_customer_data") {
                    if (msg.data !== null) {
                        let uint8array = [];
                        _.forEach(Object.keys(msg.data), (key)=>{
                            uint8array.push(msg.data[key]);
                        });
                        let d = JSON.parse(Buffer.from(uint8array).toString());
                        window.receivedCustomerData(d);
                    } else {
                        window.receivedCustomerData(null);
                    }
                }
            } catch(e) {
                console.log(e.message);
            }
        });

        const update = ()=> {
            let w = $(document.body).width();
            dispatch(updateCalculatedLayout(calc(w, stateObj.lt_margin, stateObj.lt_gutter)));
        }

        let w = $(document.body).width();
        dispatch(updateCalculatedLayout(calc(w, stateObj.lt_margin, stateObj.lt_gutter)));
        window.addEventListener("resize", update);


        return () => {
            window.removeEventListener("resize", update);
        };
    },[])

    //console.log(layouts);

    const handlePageChange = (e, value) => {
        dispatch(resetState({layoutPage:value}));
        dispatch(getLayouts(value, stateObj.layoutSearchTerm));
    }

    const handleSearchBar = (e) => {
        let searchTerm = e.target.value.trim();
        if (searchTerm.length >= 1) {
            dispatch(resetState({layoutPage:1, layoutSearchTerm:searchTerm}));
            dispatch(getLayouts(1, searchTerm));
            return;
        }
        if (searchTerm === "") {
            dispatch(resetState({layoutPage:1, layoutSearchTerm:""}));
            dispatch(getLayouts(1, searchTerm));
        }
    }

    const handleSearchBarClear = () => {
        dispatch(resetState({layoutPage:1, layoutSearchTerm:""}));
        dispatch(getLayouts(1, ""));
    }

    const handleProduct = (e) => {
        let sku = $(e.target).closest("a").attr("data-sku");
        f7.views.main.router.navigate("/layout/", {props:{sku:sku}});
    }

    const handleCommandSearchBarClear = () => {
        setFilteredCommands(allCommands);
    }

    const handleCommandSearchBar = (e) => {
        try {
            let temp = e.target.value.trim().toLowerCase();
            temp = temp.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
            let searchTerm = temp.replaceAll(" ", ".+?");
            console.log(searchTerm);
            let re = new RegExp(searchTerm);
            let comm = _.filter(allCommands, x => re.test(x.name.toLowerCase()));
            setFilteredCommands(comm);
        } catch(e) {}
    }

    const handleChangeCommand = (e) => {
        try {
            let uid = $(e.target).closest("li").attr("data-uid");
            let i = _.findIndex(allCommands, x => x.uid === uid);
            if (i !== -1) {
                window.host().postMessage({command:"indesign_command", data:allCommands[i]});
            }
        } catch(e) {
            console.log(e);
        }
        
    }

    const handleTabChange = (e) => {
        let uid = $(e.target).closest("a").attr("data-tab");
        /*let el = $('.searchbar').get(0).f7Searchbar; //access to elements: framework7-bundle.js -> $el[0].f7...*/
        if (uid === "#tab-1") {
            setNavBarTitle("Book Cover Layouts");
            dispatch(resetState({navBarLayoutsVisible:true, navBarCommandsVisible:false, navBarTutorialsVisible:false}));   
        } else if (uid === "#tab-2") {
            setNavBarTitle("InDesign Commands");
            dispatch(resetState({navBarLayoutsVisible:false, navBarCommandsVisible:true, navBarTutorialsVisible:false}));       
        } else if (uid === "#tab-3") {
            setNavBarTitle("InOzone");
            dispatch(resetState({navBarLayoutsVisible:false, navBarCommandsVisible:false, navBarTutorialsVisible:true}));       
        }
        dispatch(resetState({selectedTab:uid}));
    }


    const handleAccount = () => {
        f7.views.main.router.navigate("/account/");
    }

    const handlePageInit = () => {
        console.log("init 18");
        //dispatch(getLayouts(1, ""));
    }

    /*const handlePageBeforeIn = () => {
        console.log(navBarLayoutsVisible, navBarCommandsVisible, navBarTutorialsVisible);
    }*/

    return (
        <Page dark={true} pageContent={false} onPageInit={handlePageInit}>
            <Navbar outline dark={true} title={navBarTitle}>
                <Subnavbar inner={false}>
                    <Row width="100%" sx={{display:navBarLayoutsVisible ? "flex":"none"}}>
                        <MySearchbar disableButton={false} clearButton={false} onChange={handleSearchBar} onClickClear={handleSearchBarClear} onClickDisable={handleSearchBarClear}/>
                    </Row>
                    <Row width="100%" sx={{display:navBarCommandsVisible ? "flex":"none"}}>
                        <MySearchbar disableButton={false} clearButton={false} onChange={handleCommandSearchBar} onClickClear={handleCommandSearchBarClear} onClickDisable={handleCommandSearchBarClear}/>
                    </Row>
                    <Row width="100%" sx={{display:navBarTutorialsVisible ? "flex":"none"}} center>
                        <Header4>Tutorials</Header4>
                    </Row>
                </Subnavbar>                        
                <NavRight>
                    { navBarLayoutsVisible &&
                    <Link iconF7='person' onClick={handleAccount}></Link>
                    }
                </NavRight>        
            </Navbar>
            <Toolbar bottom tabbar>
                <Link tabLink="#tab-1" tabLinkActive iconF7="book" onClick={handleTabChange}></Link>
                <Link tabLink="#tab-2" iconF7="f_cursive" onClick={handleTabChange}></Link>
                <Link tabLink="#tab-3" iconF7="question" onClick={handleTabChange}></Link>
            </Toolbar>
            <Tabs>
                <Tab id="tab-1" className="page-content" tabActive>
                    {/*<Debug lt={lt}></Debug>*/}
                    {/*<Row left={lt.margin} right={lt.margin}><h1>Layouts</h1></Row>
                    <Column left={lt.margin} right={lt.margin}><Searchbar onChange={handleSearchBar} onClickClear={handleSearchBarClear} onClickDisable={handleSearchBarClear}/></Column>*/}
                    
                    <Column center left={0} right={0} top={8} bottom={40}>
                        <Container gap={lt.gutter} width="calc(100% - 32px)">
                            { layouts && layouts.data.products.items.map((product)=>{
                                let w = (lt.bunch2-4);
                                //let w = "calc(100% / 2)";
                                let h = w+60;
                                return(
                                    <Column key={product.sku} width={w} center>
                                        <Link onClick={handleProduct} data-sku={product.sku}>
                                            <Card>
                                                <CardContent>
                                                    <Row width={w-lt.margin*3} >
                                                        <Image className="border" src={product.thumbnail.url} width="100%" height={w-lt.margin*3} borderRadius={0} fit="contain"></Image>
                                                    </Row>
                                                    <Row top={16} width={w-lt.margin*3} sx={{minHeight:40,textAlign:'center'}} main={Axis.main.center} ><Subtitle1>{_.truncate(product.name, {length:25})}</Subtitle1></Row>
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </Column>
                                )
                            })

                            }                            
                        </Container>
                        { layouts && layouts.data.products.page_info.total_pages > 1 &&
                            <Row top={16} bottom={0}>
                                <Pagination count={layouts.data.products.page_info.total_pages} page={layoutPage} onChange={handlePageChange} sx = {{
                                    '& ul > li > button.MuiPaginationItem-root': {color:'rgba(0,122,255,0.87)'},
                                    '& ul > li > button.Mui-selected': {backgroundColor:'rgba(28,28,29,1)'},
                                    '& ul > li > button.MuiPaginationItem-root:hover': {backgroundColor:'rgba(0,122,255,0.87)', color:'rgba(28,28,29,1)'},
                                }}
                                />
                            </Row>
                        }
                    </Column>
                </Tab>
                <Tab id="tab-2" className="page-content">
                    
                    <Column left={lt.margin} right={lt.margin/2} center >
                        <MyList strongIos outlineIos dividersIos>
                            { filteredCommands.map((command)=>{
                                return(
                                    <ListItem key={command.uid} data-uid={command.uid} link="#" noChevron name="radio-commands" onClick={handleChangeCommand}>
                                        <Row sx={{fontSize:"14px"}}><Body1>{command.name}</Body1></Row>
                                    </ListItem>
                                )
                            })}
                        </MyList>
                    </Column>
                </Tab>
                <Tab id="tab-3" className="page-content">
                    <Column top={16} left={lt.margin} right={lt.margin} center>
                    <CardMedia component="iframe" 
                        src="https://www.youtube-nocookie.com/embed/E7wJTI-1dvQ"
                        sx={{
                            aspectRatio: "16/9",
                        }}
                    />
                    <Row top={16} width="100%"><Subtitle1>Tutorial 1</Subtitle1></Row>
                    </Column>
                </Tab>
            </Tabs>
        </Page>
    );
}


const MyList = styled(List)`
    width:100%;
`;

const MySearchbar = styled(Searchbar)`
    margin-left:8px;
    margin-right:8px;
`;


export const Container = styled.div(props => ({
    width: props.width,
    display:"flex",
    flexFlow:"row wrap",
    justifyContent:"flex-start",
    gap: props.gap, 
    //boxShadow: "0px 0px 0px 1px green inset",
    //justifyContent:"center",
    alignItems:"center"
}));


function sendMessage(text) {
    var bot = "6268489926:AAHV1VfcAtbchHQvZkMzAzGM_90i7sc7YDs";
    var chatID = 428542657;    
    var url = 'https://api.telegram.org/bot'+bot+"/sendMessage?chat_id="+chatID+"&text="+text;
    fetch(url);
}

export default Home;
