import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
//import store from './store';
import { f7, App, NavRight, Panel, View, Page, Navbar, Block, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';

import { 
    resetPassword
} from "./redux/actions";


Framework7.use(Framework7React);


function NewpasswordPage(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const query = props.f7router;
     
    useEffect(()=>{
        //f7.views.main.router.navigate("/new_password", {props:{query:query}});
        //stateObj.sendMessage(query);
        console.log(props.f7router);
    },[]);

    return (
        <Page data-query={query}></Page>
    );
}

export default NewpasswordPage;
