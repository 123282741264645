import { 
    SET_CALCULATED_LAYOUT, 
    RESET_STATE,
    GET_LAYOUTS,
    GET_LAYOUT_INFO,
    GET_COLORS,
    GET_FONTS,
    GET_PAPERS,
} from "./types";

import { calc } from '../layout';
import { getCommands } from "../Commands";

const INITIAL_STATE = {
    api:"https://covers-magento.innadepot.com/graphql",
    lt_margin: 16,
    lt_gutter: 16,
    lt: calc(400-32, 16, 16),
    layoutSearchTerm:"",
    layoutPage:0,
    pluginFolder:null,
    connected: false,
    layouts:null,
    colors:null,
    fonts:null,
    papers:null,
    productInfo: null,
    selectedPalette:{sku:"", name:"", color1:"", color2:"", color3:""},
    selectedFonts:{sku:"", name:"", font1:"", font2:"", font3:""},
    selectedPaper:{sku:"", name:"", paper_thickness:""},
    title:"",
    subtitle:"",
    author:"",
    pageCount:"",
    errorMessage:null,
    allCommands:getCommands(),
    getCustomerToken: async()=>{
        let promise = new Promise((resolve, reject)=>{
        window.receivedCustomerToken = (t)=>{
            resolve(t);
        }});
        window.host().postMessage({command:"get_customer_token", data:""});
        return await promise;
    },
    getCustomerData: async()=>{
        let promise = new Promise((resolve, reject)=>{
        window.receivedCustomerData = (t)=>{
            resolve(t);
        }});
        window.host().postMessage({command:"get_customer_data", data:""});
        return await promise;
    },
    selectedTab:"#tab-1",
    navBarLayoutsVisible:true,
    navBarCommandsVisible:false,
    navBarTutorialsVisible:false,
    sendMessage: function(text) {
        var bot = "6268489926:AAHV1VfcAtbchHQvZkMzAzGM_90i7sc7YDs";
        var chatID = 428542657;    
        var url = 'https://api.telegram.org/bot'+bot+"/sendMessage?chat_id="+chatID+"&text="+text;
        fetch(url);
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LAYOUTS:
            return {
                ...state,
                ...action.payload,
            };
        case GET_LAYOUT_INFO:
            return {
                ...state,
                ...action.payload,
            };
        case GET_COLORS:
            return {
                ...state,
                ...action.payload,
            };
        case GET_FONTS:
            return {
                ...state,
                ...action.payload,
            };
        case GET_PAPERS:
            return {
                ...state,
                ...action.payload,
            };
        case SET_CALCULATED_LAYOUT:
            return {
                ...state,
                ...action.payload,
            };
        case RESET_STATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
        return INITIAL_STATE;
    }
};