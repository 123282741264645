import "./App.css";
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { f7, NavRight, Block, Page, Navbar, Searchbar, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, Stepper, Card, CardHeader, CardContent, CardFooter, BlockTitle, List, ListInput, ListItem, Toggle, Progressbar } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, Layer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, Title } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import styled from '@emotion/styled';
import { Image } from './Components';
import { calc, Debug } from './layout';

import { 
    resetState,
    getColors
} from "./redux/actions";


function ColorsPage(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    const [showPreloader, setShowPreloader] = useState(true);
    const [page, setPage] = useState(1);
    const allowInfinite = useRef(true);
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState({sku:"", name:"", color1:"", color2:"", color3:""});
    const selectedPalette = stateObj.selectedPalette;

    //console.log(colors);

    useEffect(()=>{
        dispatch(getColors(page, (result)=>{
            console.log(result);
            if (result.data.products.page_info.total_pages === 1) {
                allowInfinite.current = false;
                setShowPreloader(false);
            }
            if (selectedPalette.sku !== "") {
                setSelected(selectedPalette);    
            } /*else {
                let s = {sku:result.data.products.items[0].sku, name:result.data.products.items[0].name, color1:result.data.products.items[0].color1, color2:result.data.products.items[0].color2, color3:result.data.products.items[0].color3};
                setSelected(s);
                dispatch(resetState({selectedPalette:s}));
            }*/
            setItems(result.data.products.items);
        }));
    },[])

    const loadMore = () => {
        if (!allowInfinite.current) return;
        allowInfinite.current = false;
        let nextPage = page+1;
        dispatch(getColors(nextPage, (result)=>{
            allowInfinite.current = true;
            let temp = [...items];
            temp = temp.concat(result.data.products.items);
            setItems(temp);
            setPage(nextPage);
            if (nextPage >= result.data.products.page_info.total_pages) {
                allowInfinite.current = false;
                setShowPreloader(false);
            }
        }));
    };

    const handleChange = (e) => {
        let sku = $(e.target).closest("li").attr("data-sku");
        let i = _.findIndex(items, x => x.sku === sku);
        let name = "";
        let color1 = "";
        let color2 = "";
        let color3 = "";
        if (i !== -1) {
            name = items[i].name;
            color1 = items[i].color1;
            color2 = items[i].color2;
            color3 = items[i].color3;
        }
        dispatch(resetState({selectedPalette:{sku:sku, name:name, color1:color1, color2:color2, color3:color3}}));
        setSelected({sku:sku, name:name});
    }

    let colWidth = lt.bunch4;
    if (lt.max >= 8) {
        colWidth = lt.bunch8;
    }

    return (
        <Page id="home-page" dark infinite infiniteDistance={50} infinitePreloader={showPreloader} onInfinite={loadMore}>
            <Navbar dark outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Colors</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
            <Column>
                <Column width={lt.containerWidth-lt.margin} >
                    <Column left={lt.margin} width={colWidth} >
                        <Row height={10}></Row>
                        <List dividersIos mediaList outlineIos strongIos>
                            {items.map((item)=>{
                                return(
                                    <ListItem key={item.sku} data-sku={item.sku} radio name="radio-colors" checked={item.sku === selected.sku ? true : false } onChange={handleChange}>
                                        <Row>
                                            <Column>
                                                <img src={item.image.url} width={80} height={80} style={{ borderRadius: '8px' }}></img>
                                            </Column>
                                            <Column left={lt.margin}>
                                                <Row><Subtitle2>{item.name}</Subtitle2></Row>
                                                <Row top={12}><div className="paper-short-description" dangerouslySetInnerHTML={{__html: item.short_description.html}} /></Row>
                                            </Column>
                                        </Row>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Column>
                </Column>
            </Column>
        </Page>
    );
}


export default ColorsPage;
