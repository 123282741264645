import uuid from 'react-uuid';
import _ from 'lodash';

export const getCommands = function(){

    const commands = [
        {"name":"Tool ▸ Selection (V, Escape)", "key":"tool", "code":"1936018548"},
        {"name":"Tool ▸ Direct Selection (A)", "key":"tool", "code":"1685277812"},
        {"name":"Tool ▸ Page (Shift+P)", "key":"tool", "code":"1936741484"},
        {"name":"Tool ▸ Gap (U)", "key":"tool", "code":"1734430836"},
        {"name":"Tool ▸ Type (T)", "key":"tool", "code":"1954107508"},
        {"name":"Tool ▸ Type on a Path (Shift+T)", "key":"tool", "code":"1953452148"},
        {"name":"Tool ▸ Line (\\)", "key":"tool", "code":"1819169900"},
        {"name":"Tool ▸ Pen (P)", "key":"tool", "code":"1885687412"},
        {"name":"Tool ▸ Add Anchor Point (=)", "key":"tool", "code":"1633767540"},
        {"name":"Tool ▸ Delete Anchor Point (-)", "key":"tool", "code":"1684099188"},
        {"name":"Tool ▸ Pencil (N)", "key":"tool", "code":"1886274412"},
        {"name":"Tool ▸ Smooth", "key":"tool", "code":"1936544872"},
        {"name":"Tool ▸ Erase", "key":"tool", "code":"1701991269"},
        {"name":"Tool ▸ Rectangle Frame (F)", "key":"tool", "code":"1919308908"},
        {"name":"Tool ▸ Ellipse Frame", "key":"tool", "code":"1701598316"},
        {"name":"Tool ▸ Polygon Frame", "key":"tool", "code":"1885754476"},
        {"name":"Tool ▸ Rectangle (M)", "key":"tool", "code":"1919243372"},
        {"name":"Tool ▸ Ellipse (L)", "key":"tool", "code":"1701598316"},
        {"name":"Tool ▸ Polygon", "key":"tool", "code":"1886147692"},
        {"name":"Tool ▸ Scissors (C)", "key":"tool", "code":"1935891060"},
        {"name":"Tool ▸ Scale", "key":"tool", "code":"1935889516"},
        {"name":"Tool ▸ Free Transform (E)", "key":"tool", "code":"1718899820"},
        {"name":"Tool ▸ Rotate", "key":"tool", "code":"1919898732"},
        {"name":"Tool ▸ Shear", "key":"tool", "code":"1936217196"},
        {"name":"Tool ▸ Gradient Swatch (G)", "key":"tool", "code":"1735611500"},
        {"name":"Tool ▸ Gradient Feather (Shift+G)", "key":"tool", "code":"1734759532"},
        {"name":"Tool ▸ Note", "key":"tool", "code":"1852789868"},
        {"name":"Tool ▸ Color Theme (Shift+I)", "key":"shortcut", "code":"+I"},
        {"name":"Tool ▸ Eyedropper", "key":"tool", "code":"1701074028"},
        {"name":"Tool ▸ Measure", "key":"tool", "code":"1835357292"},
        {"name":"Tool ▸ Hand (H)", "key":"tool", "code":"1751209068"},
        {"name":"Tool ▸ Zoom (Z)", "key":"tool", "code":"2053985388"},
        {"name":"Tool ▸ Fill/Stroke (X)", "key":"shortcut", "code":"x"},
        {"name":"Tool ▸ Swap Fill and Stroke (Shift+X)", "key":"shortcut", "code":"+X"},
        {"name":"Tool ▸ Default Fill and Stroke (D)", "key":"shortcut", "code":"D"},
        {"name":"Tool ▸ Formatting affects text/container (J)", "key":"shortcut", "code":"J"},
        {"name":"Tool ▸ Apply None (/)", "key":"shortcut", "code":"/"},
        {"name":"Tool ▸ Apply Color (,)", "key":"shortcut", "code":","},
        {"name":"Tool ▸ Apply Gradient (.)", "key":"shortcut", "code":"."},
        {"name":"Tool ▸ Frame Edges (Ctrl+H)", "key":"shortcut", "code":"^h"},
        {"name":"Tool ▸ Rulers (Ctrl+R)", "key":"shortcut", "code":"^r"},
        {"name":"Tool ▸ Guides (Ctrl+;)", "key":"shortcut", "code":"^;"},
        {"name":"Tool ▸ Smart Guides (Ctrl+U)", "key":"shortcut", "code":"^u"},
        {"name":"Tool ▸ Baseline Grid (Ctrl+Alt+')", "key":"shortcut", "code":"^!'"},
        {"name":"Tool ▸ Hidden Characters", "key":"shortcut", "code":"^!i"},
        {"name":"Tool ▸ Normal (W)", "key":"shortcut", "code":"w"},
        {"name":"Tool ▸ Preview to Page", "key":"screen_mode", "code":"1936552048"},
        {"name":"Tool ▸ Preview off", "key":"screen_mode", "code":"1936552047"},
        {"name":"Tool ▸ Preview to Bleed", "key":"screen_mode", "code":"1936552034"},
        {"name":"Tool ▸ Preview to Slug", "key":"screen_mode", "code":"1936552051"},
        {"name":"Tool ▸ Presentation (Shift+W)", "key":"screen_mode", "code":"1936552046"},
        {"name":"Tool ▸ Convert Direction Point", "key":"tool", "code":"1667518580"},
        {"name":"Tool ▸ Ellipse Frame", "key":"tool", "code":"1701205100"},
        {"name":"Tool ▸ Motion Path", "key":"tool", "code":"1836078188"},
        {"name":"Tool ▸ Content Collector (B)", "key":"shortcut", "code":"b"},
        {"name":"Tool ▸ Place Cursor", "key":"tool", "code":"1885557868"},
        {"name":"Tool ▸ Table", "key":"tool", "code":"1952601196"},    
        {"name":"File ▸ New ▸ Document (Ctrl+N)", "key":"menu_action", "code":["File", "New", "Document..."]},
        {"name":"File ▸ Open (Ctrl+O)", "key":"shortcut", "code":"^o"},
        {"name":"File ▸ Browse in Bridge (Ctrl+Alt+O)", "key":"shortcut", "code":"^!o"},
        {"name":"File ▸ Close (Ctrl+W)", "key":"shortcut", "code":"^w"}, 
        {"name":"File ▸ Save (Ctrl+S)", "key":"shortcut", "code":"^s"},
        {"name":"File ▸ Save as (Ctrl+Shift+S)", "key":"shortcut", "code":"^+s"},
        {"name":"File ▸ Save as Copy (Ctrl+Alt+S)", "key":"shortcut", "code":"^!s"},
        {"name":"File ▸ Place (Ctrl+D)", "key":"shortcut", "code":"^d"},
        {"name":"File ▸ Export (Ctrl+E)", "key":"shortcut", "code":"^e"},
        {"name":"File ▸ Document Setup (Ctrl+Alt+P)", "key":"shortcut", "code":"^!p"},
        {"name":"File ▸ Adjust Layout (Alt+Shift+P)", "key":"shortcut", "code":"!+p"},
        {"name":"File ▸ File Info (Ctrl+Alt+Shift+I)", "key":"shortcut", "code":"^+!i"},
        {"name":"File ▸ Package (Ctrl+Alt+Shift+P)", "key":"shortcut", "code":"^+!p"},
        {"name":"File ▸ Print (Ctrl+P)", "key":"shortcut", "code":"^p"},
        {"name":"File ▸ Print Booklet", "key":"menu_action", "code":["File", "Print Booklet..."]},
        {"name":"File ▸ Check In", "key":"menu_action", "code":["File", "Check In..."]},
        {"name":"File ▸ Revert", "key":"menu_action", "code":["File", "Revert"]},
        {"name":"File ▸ Place from CC Libraries", "key":"menu_action", "code":["File", "Place from CC Libraries..."]},
        {"name":"File ▸ Import XML", "key":"menu_action", "code":["File", "Import XML..."]},
        {"name":"File ▸ Import PDF Comments", "key":"menu_action", "code":["File", "Import PDF Comments..."]},
        {"name":"File ▸ Share for Review", "key":"menu_action", "code":["File", "Share for Review..."]},
        {"name":"File ▸ Publish Online", "key":"menu_action", "code":["File", "Publish Online..."]},
        {"name":"File ▸ Publish Online Dashboard", "key":"menu_action", "code":["File", "Publish Online Dashboard..."]},
        {"name":"File ▸ User", "key":"menu_action", "code":["File", "User..."]},
        {"name":"File ▸ New ▸ Book", "key":"menu_action", "code":["File", "New", "Book..."]},
        {"name":"File ▸ New ▸ Library", "key":"menu_action", "code":["File", "New", "Library..."]},
        {"name":"File ▸ Adobe PDF Presets ▸ Define", "key":"menu_action", "code":["File", "Adobe PDF Presets", "Define..."]},
        {"name":"File ▸ Adobe PDF Presets ▸ [High Quality Print]", "key":"menu_action", "code":["File", "Adobe PDF Presets", "[High Quality Print]..."]},
        {"name":"File ▸ Adobe PDF Presets ▸ [PDF/X-1a:2001]", "key":"menu_action", "code":["File", "Adobe PDF Presets", "[PDF/X-1a:2001]..."]},
        {"name":"File ▸ Adobe PDF Presets ▸ [PDF/X-3:2002]", "key":"menu_action", "code":["File", "Adobe PDF Presets", "[PDF/X-3:2002]..."]},
        {"name":"File ▸ Adobe PDF Presets ▸ [PDF/X-4:2008]", "key":"menu_action", "code":["File", "Adobe PDF Presets", "[PDF/X-4:2008]..."]},
        {"name":"File ▸ Adobe PDF Presets ▸ [Press Quality]", "key":"menu_action", "code":["File", "Adobe PDF Presets", "[Press Quality]..."]},
        {"name":"File ▸ Adobe PDF Presets ▸ [Smallest File Size]", "key":"menu_action", "code":["File", "Adobe PDF Presets", "[Smallest File Size]..."]},
        {"name":"File ▸ Document Presets ▸ Define", "key":"menu_action", "code":["File", "Document Presets", "Define..."]},
        {"name":"File ▸ Document Presets ▸ [Default]", "key":"menu_action", "code":["File", "Document Presets", "[Default]..."]},
        {"name":"Edit ▸ Undo (Ctrl+Z)", "key":"shortcut", "code":"^z"},
        {"name":"Edit ▸ Redo (Ctrl+Shift+Z)", "key":"shortcut", "code":"^+z"},
        {"name":"Edit ▸ Cut (Ctrl+X)", "key":"shortcut", "code":"^x"},
        {"name":"Edit ▸ Copy (Ctrl+C)", "key":"shortcut", "code":"^c"},
        {"name":"Edit ▸ Paste (Ctrl+V)", "key":"shortcut", "code":"^v"},
        {"name":"Edit ▸ Paste without Formatting (Ctrl+Shift+V)", "key":"shortcut", "code":"^+v"},
        {"name":"Edit ▸ Paste into (Ctrl+Alt+V)", "key":"shortcut", "code":"^!v"},
        {"name":"Edit ▸ Paste in Place (Ctrl+Alt+Shift+V)", "key":"shortcut", "code":"^+!v"},
        {"name":"Edit ▸ Duplicate (Ctrl+Alt+Shift+D)", "key":"shortcut", "code":"^+!d"},
        {"name":"Edit ▸ Step and Repeat (Ctrl+Alt+U)", "key":"shortcut", "code":"^!u"},
        {"name":"Edit ▸ Place and Link", "key":"menu_action", "code":["Edit", "Place and Link"]},
        {"name":"Edit ▸ Select All (Ctrl+A)", "key":"shortcut", "code":"^a"},
        {"name":"Edit ▸ Deselect All (Ctrl+Shift+A)", "key":"shortcut", "code":"^+a"},
        {"name":"Edit ▸ InCopy ▸ Add Selection to Assignment ▸ New", "key":"menu_action", "code":["Edit", "InCopy", "Add Selection to Assignment", "New..."]},
        {"name":"Edit ▸ InCopy ▸ Add Layer to Assignment ▸ New", "key":"menu_action", "code":["Edit", "InCopy", "Add Layer to Assignment", "New..."]},
        {"name":"Edit ▸ InCopy ▸ Add All Stories to Assignment ▸ New", "key":"menu_action", "code":["Edit", "InCopy", "Add All Stories to Assignment", "New..."]},
        {"name":"Edit ▸ InCopy ▸ Add All Graphics to Assignment ▸ New", "key":"menu_action", "code":["Edit", "InCopy", "Add All Graphics to Assignment", "New..."]},
        {"name":"Edit ▸ InCopy ▸ Export ▸ Selection", "key":"menu_action", "code":["Edit", "InCopy", "Export", "Selection..."]},
        {"name":"Edit ▸ InCopy ▸ Export ▸ Layer", "key":"menu_action", "code":["Edit", "InCopy", "Export", "Layer..."]},
        {"name":"Edit ▸ InCopy ▸ Export ▸ All Stories", "key":"menu_action", "code":["Edit", "InCopy", "Export", "All Stories..."]},
        {"name":"Edit ▸ InCopy ▸ Export ▸ All Graphics", "key":"menu_action", "code":["Edit", "InCopy", "Export", "All Graphics..."]},
        {"name":"Edit ▸ InCopy ▸ Export ▸ All Graphics and Stories", "key":"menu_action", "code":["Edit", "InCopy", "Export", "All Graphics and Stories..."]},
        {"name":"Edit ▸ InCopy ▸ Check Out (Ctrl+F9)", "key":"menu_action", "code":["Edit", "InCopy", "Check Out"]},
        {"name":"Edit ▸ InCopy ▸ Check In (Ctrl+Shift+F9)", "key":"menu_action", "code":["Edit", "InCopy", "Check In"]},
        {"name":"Edit ▸ InCopy ▸ Check In All (Ctrl+Alt+Shift+F9)", "key":"menu_action", "code":["Edit", "InCopy", "Check In All"]},
        {"name":"Edit ▸ Edit Original", "key":"menu_action", "code":["Edit", "Edit Original"]},
        {"name":"Edit ▸ Edit With ▸ Other", "key":"menu_action", "code":["Edit", "Edit With", "Other..."]},
        {"name":"Edit ▸ Go To Source ", "key":"menu_action", "code":["Edit", "Go To Source"]},
        {"name":"Edit ▸ Edit in Story Editor (Ctrl+Y)", "key":"shortcut", "code":"^y"},
        {"name":"Edit ▸ Edit in Copy Editor (Beta) (Alt+Shift+Y)", "key":"shortcut", "code":"!+y"},
        {"name":"Edit ▸ Find Similar Images ", "key":"menu_action", "code":["Edit", "Find Similar Images"]},
        {"name":"Edit ▸ Quick Apply (Ctrl+Enter)", "key":"shortcut", "code":"^~"},
        {"name":"Edit ▸ Find/Change (Ctrl+F)", "key":"shortcut", "code":"^f"},
        {"name":"Edit ▸ Find Next (Ctrl+Alt+F)", "key":"shortcut", "code":"^!f"},
        {"name":"Edit ▸ Spelling ▸ Check Spelling (Ctrl+I)", "key":"menu_action", "code":["Edit", "Spelling", "Check Spelling..."]},
        {"name":"Edit ▸ Spelling ▸ Autocorrect", "key":"menu_action", "code":["Edit", "Spelling", "Autocorrect"]},
        {"name":"Edit ▸ Spelling ▸ Dynamic Spelling", "key":"menu_action", "code":["Edit", "Spelling", "Dynamic Spelling"]},
        {"name":"Edit ▸ Spelling ▸ User Dictionary", "key":"menu_action", "code":["Edit", "Spelling", "User Dictionary..."]},
        {"name":"Edit ▸ Transparency Blend Space ▸ Document RGB", "key":"menu_action", "code":["Edit", "Transparency Blend Space", "Document RGB"]},
        {"name":"Edit ▸ Transparency Blend Space ▸ Document CMYK", "key":"menu_action", "code":["Edit", "Transparency Blend Space", "Document CMYK"]},
        {"name":"Edit ▸ Transparency Flattener Presets", "key":"menu_action", "code":["Edit", "Transparency Flattener Presets..."]},
        {"name":"Edit ▸ Migrate previous Local Settings", "key":"menu_action", "code":["Edit", "Migrate previous Local Settings..."]},
        {"name":"Edit ▸ Color Settings", "key":"menu_action", "code":["Edit", "Color Settings..."]},
        {"name":"Edit ▸ Assign Profiles", "key":"menu_action", "code":["Edit", "Assign Profiles..."]},
        {"name":"Edit ▸ Convert to Profile", "key":"menu_action", "code":["Edit", "Convert to Profile..."]},
        {"name":"Edit ▸ Keyboard Shortcuts", "key":"menu_action", "code":["Edit", "Keyboard Shortcuts..."]},
        {"name":"Edit ▸ Menus", "key":"menu_action", "code":["Edit", "Menus..."]},
        {"name":"Edit ▸ Preferences ▸ General (Ctrl+K)", "key":"shortcut", "code":"^k"},
        {"name":"Layout ▸ Pages ▸ Add Page", "key":"menu_action", "code":["Layout", "Pages", "Add Page"]},
        {"name":"Layout ▸ Pages ▸ Insert Pages", "key":"menu_action", "code":["Layout", "Pages", "Insert Pages..."]},
        {"name":"Layout ▸ Pages ▸ Move Pages", "key":"menu_action", "code":["Layout", "Pages", "Move Pages..."]},
        {"name":"Layout ▸ Pages ▸ Duplicate Spread", "key":"menu_action", "code":["Layout", "Pages", "Duplicate Spread"]},
        {"name":"Layout ▸ Pages ▸ Delete Paged", "key":"menu_action", "code":["Layout", "Pages", "Delete Pages..."]},
        {"name":"Layout ▸ Pages ▸ Print Spread", "key":"menu_action", "code":["Layout", "Pages", "Print Spread..."]},
        {"name":"Layout ▸ Pages ▸ Apply Parent to Pages", "key":"menu_action", "code":["Layout", "Pages", "Apply Parent to Pages..."]},
        {"name":"Layout ▸ Pages ▸ Page Transitions ▸ Choose", "key":"menu_action", "code":["Layout", "Pages", "Page Transitions", "Choose..."]},
        {"name":"Layout ▸ Pages ▸ Page Transitions ▸ Edit", "key":"menu_action", "code":["Layout", "Pages", "Page Transitions", "Edit"]},
        {"name":"Layout ▸ Pages ▸ Page Transitions ▸ Clear All", "key":"menu_action", "code":["Layout", "Pages", "Page Transitions", "Clear All"]},
        {"name":"Layout ▸ Margins and Columns", "key":"menu_action", "code":["Layout", "Margins and Columns..."]},
        {"name":"Layout ▸ Ruler Guides", "key":"menu_action", "code":["Layout", "Ruler Guides..."]},
        {"name":"Layout ▸ Create Guides", "key":"menu_action", "code":["Layout", "Create Guides..."]},
        {"name":"Layout ▸ Create Alternate Layout", "key":"menu_action", "code":["Layout", "Create Alternate Layout..."]},
        {"name":"Layout ▸ Liquid Layout", "key":"menu_action", "code":["Layout", "Liquid Layout"]},
        {"name":"Layout ▸ First Page", "key":"menu_action", "code":["Layout", "First Page"]},
        {"name":"Layout ▸ Previous Page", "key":"menu_action", "code":["Layout", "Previous Page"]},
        {"name":"Layout ▸ Next Page", "key":"menu_action", "code":["Layout", "Next Page"]},
        {"name":"Layout ▸ Last Page", "key":"menu_action", "code":["Layout", "Last Page"]},
        {"name":"Layout ▸ Next Spread", "key":"menu_action", "code":["Layout", "Next Spread"]},
        {"name":"Layout ▸ Previous Spread", "key":"menu_action", "code":["Layout", "Previous Spread"]},
        {"name":"Layout ▸ Go To Page", "key":"shortcut", "code":"^j"},
        {"name":"Layout ▸ Go Back", "key":"menu_action", "code":["Layout", "Go Back"]},
        {"name":"Layout ▸ Go Forward", "key":"menu_action", "code":["Layout", "Go Forward"]},
        {"name":"Layout ▸ Numbering & Section Options", "key":"menu_action", "code":["Layout", "Numbering & Section Options..."]},
        {"name":"Layout ▸ Table of Contents", "key":"menu_action", "code":["Layout", "Table of Contents..."]},
        {"name":"Layout ▸ Update Table of Contents", "key":"menu_action", "code":["Layout", "Update Table of Contents"]},
        {"name":"Layout ▸ Table of Contents Styles", "key":"menu_action", "code":["Layout", "Table of Contents Styles..."]},
        {"name":"Type ▸ Character", "key":"menu_action", "code":["Type", "Character"]},
        {"name":"Type ▸ Paragraph", "key":"shortcut", "code":"^!t"},
        {"name":"Type ▸ Tabs", "key":"shortcut", "code":"^+t"},
        {"name":"Type ▸ Glyphs", "key":"menu_action", "code": ["Type", "Glyphs"]},
        {"name":"Type ▸ Story", "key":"menu_action", "code": ["Type", "Story"]},
        {"name":"Type ▸ Character Styles", "key":"shortcut", "code":"^+{F11}"},
        {"name":"Type ▸ Paragraph Styles", "key":"shortcut", "code": "^{F11}"},
        {"name":"Type ▸ Create Outlines", "key":"menu_action", "code": ["Type", "Create Outlines"]},
        {"name":"Type ▸ Find/Replace Font", "key":"menu_action", "code": ["Type", "Find/Replace Font..."]},
        {"name":"Type ▸ Change Case ▸ UPPERCASE", "key":"menu_action", "code": ["Type", "Change Case", "UPPERCASE"]},
        {"name":"Type ▸ Change Case ▸ lowercase", "key":"menu_action", "code": ["Type", "Change Case", "lowercase"]},
        {"name":"Type ▸ Change Case ▸ Title Case", "key":"menu_action", "code": ["Type", "Change Case", "Title Case"]},
        {"name":"Type ▸ Change Case ▸ Sentence case", "key":"menu_action", "code": ["Type", "Change Case", "Sentence case"]},
        {"name":"Type ▸ Type on a Path ▸ Options", "key":"menu_action", "code": ["Type", "Type on a Path", "Options..."]},
        {"name":"Type ▸ Type on a Path ▸ Delete Type from Path", "key":"menu_action", "code": ["Type", "Type on a Path", "Delete Type from Path"]},
        {"name":"Type ▸ Notes ▸ Notes Mode", "key":"menu_action", "code": ["Type", "Notes", "Notes Mode"]},
        {"name":"Type ▸ Notes ▸ New Note", "key":"menu_action", "code": ["Type", "Notes", "New Note"]},
        {"name":"Type ▸ Notes ▸ Open Note", "key":"menu_action", "code": ["Type", "Notes", "Open Note"]},
        {"name":"Type ▸ Notes ▸ Delete Note", "key":"menu_action", "code": ["Type", "Notes", "Delete Note"]},
        {"name":"Type ▸ Notes ▸ Previous Note", "key":"menu_action", "code": ["Type", "Notes", "Previous Note"]},
        {"name":"Type ▸ Notes ▸ Next Note", "key":"menu_action", "code": ["Type", "Notes", "Next Note"]},
        {"name":"Type ▸ Notes ▸ Convert to Note", "key":"menu_action", "code": ["Type", "Notes", "Convert to Note"]},
        {"name":"Type ▸ Notes ▸ Convert to Text", "key":"menu_action", "code": ["Type", "Notes", "Convert to Text"]},
        {"name":"Type ▸ Notes ▸ Split Note", "key":"menu_action", "code": ["Type", "Notes", "Split Note"]},
        {"name":"Type ▸ Notes ▸ Expand/Collapse Notes in Story", "key":"menu_action", "code": ["Type", "Notes", "Expand/Collapse Notes in Story"]},
        {"name":"Type ▸ Notes ▸ Remove Notes from Story", "key":"menu_action", "code": ["Type", "Notes", "Remove Notes from Story"]},
        {"name":"Type ▸ Notes ▸ Remove All Notes", "key":"menu_action", "code": ["Type", "Notes", "Remove All Notes"]},
        {"name":"Type ▸ Track Changes ▸ Track Changes in Current Story", "key":"menu_action", "code": ["Type", "Track Changes", "Track Changes in Current Story"]},
        {"name":"Type ▸ Track Changes ▸ Enable Tracking in All Stories", "key":"menu_action", "code": ["Type", "Track Changes", "Enable Tracking in All Stories"]},
        {"name":"Type ▸ Track Changes ▸ Disable Tracking in All Stories", "key":"menu_action", "code": ["Type", "Track Changes", "Disable Tracking in All Stories"]},
        {"name":"Type ▸ Track Changes ▸ Previous Change", "key":"menu_action", "code": ["Type", "Track Changes", "Previous Change"]},
        {"name":"Type ▸ Track Changes ▸ Next Change", "key":"menu_action", "code": ["Type", "Track Changes", "Next Change"]},
        {"name":"Type ▸ Track Changes ▸ Accept Change", "key":"menu_action", "code": ["Type", "Track Changes", "Accept Change"]},
        {"name":"Type ▸ Track Changes ▸ Reject Change", "key":"menu_action", "code": ["Type", "Track Changes", "Reject Change"]},
        {"name":"Type ▸ Track Changes ▸ Accept Change, Find Next", "key":"menu_action", "code": ["Type", "Track Changes", "Accept Change, Find Next"]},
        {"name":"Type ▸ Track Changes ▸ Reject Change, Find Next", "key":"menu_action", "code": ["Type", "Track Changes", "Reject Change, Find Next"]},
        {"name":"Type ▸ Track Changes ▸ Accept All Changes ▸ In This Story", "key":"menu_action", "code": ["Type", "Track Changes", "Accept All Changes", "In This Story"]},
        {"name":"Type ▸ Track Changes ▸ Accept All Changes ▸ In This Document", "key":"menu_action", "code": ["Type", "Track Changes", "Accept All Changes", "In This Document"]},
        {"name":"Type ▸ Track Changes ▸ Reject All Changes ▸ In This Story", "key":"menu_action", "code": ["Type", "Track Changes", "Reject All Changes", "In This Story"]},
        {"name":"Type ▸ Track Changes ▸ Reject All Changes ▸ In This Document", "key":"menu_action", "code": ["Type", "Track Changes", "Reject All Changes", "In This Document"]},
        {"name":"Type ▸ Track Changes ▸ Accept All Changes by This User ▸ In This Story", "key":"menu_action", "code": ["Type", "Track Changes", "Accept All Changes by This User", "In This Story"]},
        {"name":"Type ▸ Track Changes ▸ Accept All Changes by This User ▸ In This Document", "key":"menu_action", "code": ["Type", "Track Changes", "Accept All Changes by This User", "In This Document"]},
        {"name":"Type ▸ Track Changes ▸ Reject All Changes by This User ▸ In This Story", "key":"menu_action", "code": ["Type", "Track Changes", "Reject All Changes by This User", "In This Story"]},
        {"name":"Type ▸ Track Changes ▸ Reject All Changes by This User ▸ In This Document", "key":"menu_action", "code": ["Type", "Track Changes", "Reject All Changes by This User", "In This Document"]},
        {"name":"Type ▸ Insert Footnote", "key":"menu_action", "code": ["Type", "Insert Footnote"]},
        {"name":"Type ▸ Document Footnote Options", "key":"menu_action", "code": ["Type", "Document Footnote Options..."]},
        {"name":"Type ▸ Insert Endnote", "key":"menu_action", "code": ["Type", "Insert Endnote"]},
        {"name":"Type ▸ Document Endnote Options", "key":"menu_action", "code": ["Type", "Document Endnote Options..."]},
        {"name":"Type ▸ Convert Footnotes and Endnotes", "key":"menu_action", "code": ["Type", "Convert Footnotes and Endnotes..."]},
        {"name":"Type ▸ Hyperlinks & Cross-References ▸ New Hyperlink", "key":"menu_action", "code": ["Type", "Hyperlinks & Cross-References", "New Hyperlink..."]},
        {"name":"Type ▸ Hyperlinks & Cross-References ▸ New Hyperlink From URL", "key":"menu_action", "code": ["Type", "Hyperlinks & Cross-References", "New Hyperlink From URL"]},
        {"name":"Type ▸ Hyperlinks & Cross-References ▸ Convert URLs to Hyperlinks", "key":"menu_action", "code": ["Type", "Hyperlinks & Cross-References", "Convert URLs to Hyperlinks..."]},
        {"name":"Type ▸ Hyperlinks & Cross-References ▸ Edit Hyperlink", "key":"menu_action", "code": ["Type", "Hyperlinks & Cross-References", "Edit Hyperlink..."]},
        {"name":"Type ▸ Hyperlinks & Cross-References ▸ Insert Cross-Reference", "key":"menu_action", "code": ["Type", "Hyperlinks & Cross-References", "Insert Cross-Reference..."]},
        {"name":"Type ▸ Hyperlinks & Cross-References ▸ Cross-Reference Options", "key":"menu_action", "code": ["Type", "Hyperlinks & Cross-References", "Cross-Reference Options..."]},
        {"name":"Type ▸ Hyperlinks & Cross-References ▸ Update Cross-Reference", "key":"menu_action", "code": ["Type", "Hyperlinks & Cross-References", "Update Cross-Reference"]},
        {"name":"Type ▸ Text Variables ▸ Define", "key":"menu_action", "code": ["Type", "Text Variables", "Define..."]},
        {"name":"Type ▸ Text Variables ▸ Insert Variable ▸ Chapter Number", "key":"menu_action", "code": ["Type", "Text Variables", "Insert Variable", "Chapter Number"]},
        {"name":"Type ▸ Text Variables ▸ Insert Variable ▸ Creation Date", "key":"menu_action", "code": ["Type", "Text Variables", "Insert Variable", "Creation Date"]},
        {"name":"Type ▸ Text Variables ▸ Insert Variable ▸ File Name", "key":"menu_action", "code": ["Type", "Text Variables", "Insert Variable", "File Name"]},
        {"name":"Type ▸ Text Variables ▸ Insert Variable ▸ Image Name", "key":"menu_action", "code": ["Type", "Text Variables", "Insert Variable", "Image Name"]},
        {"name":"Type ▸ Text Variables ▸ Insert Variable ▸ Last Page Number", "key":"menu_action", "code": ["Type", "Text Variables", "Insert Variable", "Last Page Number"]},
        {"name":"Type ▸ Text Variables ▸ Insert Variable ▸ Modification Date", "key":"menu_action", "code": ["Type", "Text Variables", "Insert Variable", "Modification Date"]},
        {"name":"Type ▸ Text Variables ▸ Insert Variable ▸ Output Date", "key":"menu_action", "code": ["Type", "Text Variables", "Insert Variable", "Output Date"]},
        {"name":"Type ▸ Text Variables ▸ Insert Variable ▸ Running Header", "key":"menu_action", "code": ["Type", "Text Variables", "Insert Variable", "Running Header"]},
        {"name":"Type ▸ Text Variables ▸ Convert Variable to Text", "key":"menu_action", "code": ["Type", "Text Variables", "Convert Variable to Text"]},
        {"name":"Type ▸ Bulleted & Numbered Lists ▸ Apply Bullets", "key":"menu_action", "code": ["Type", "Bulleted & Numbered Lists", "Apply Bullets"]},
        {"name":"Type ▸ Bulleted & Numbered Lists ▸ Apply Numbers", "key":"menu_action", "code": ["Type", "Bulleted & Numbered Lists", "Apply Numbers"]},
        {"name":"Type ▸ Bulleted & Numbered Lists ▸ Restart/Continue Numbering", "key":"menu_action", "code": ["Type", "Bulleted & Numbered Lists", "Restart/Continue Numbering"]},
        {"name":"Type ▸ Bulleted & Numbered Lists ▸ Convert Bullets and Numbering to Text", "key":"menu_action", "code": ["Type", "Bulleted & Numbered Lists", "Convert Bullets and Numbering to Text"]},
        {"name":"Type ▸ Bulleted & Numbered Lists ▸ Define Lists", "key":"menu_action", "code": ["Type", "Bulleted & Numbered Lists", "Define Lists..."]},
        {"name":"Type ▸ Insert Special Character ▸ Symbols ▸ Bullet Character", "key":"menu_action", "code": ["Type", "Insert Special Character", "Symbols", "Bullet Character"]},
        {"name":"Type ▸ Insert Special Character ▸ Symbols ▸ Copyright Symbol", "key":"menu_action", "code": ["Type", "Insert Special Character", "Symbols", "Copyright Symbol"]},
        {"name":"Type ▸ Insert Special Character ▸ Symbols ▸ Ellipsis", "key":"menu_action", "code": ["Type", "Insert Special Character", "Symbols", "Ellipsis"]},
        {"name":"Type ▸ Insert Special Character ▸ Symbols ▸ Paragraph Symbol", "key":"menu_action", "code": ["Type", "Insert Special Character", "Symbols", "Paragraph Symbol"]},
        {"name":"Type ▸ Insert Special Character ▸ Symbols ▸ Registered Trademark Symbol", "key":"menu_action", "code": ["Type", "Insert Special Character", "Symbols", "Registered Trademark Symbol"]},
        {"name":"Type ▸ Insert Special Character ▸ Symbols ▸ Section Symbol", "key":"menu_action", "code": ["Type", "Insert Special Character", "Symbols", "Section Symbol"]},
        {"name":"Type ▸ Insert Special Character ▸ Symbols ▸ Trademark Symbol", "key":"menu_action", "code": ["Type", "Insert Special Character", "Symbols", "Trademark Symbol"]},
        {"name":"Type ▸ Insert Special Character ▸ Markers ▸ Current Page Number", "key":"menu_action", "code": ["Type", "Insert Special Character", "Markers", "Current Page Number"]},
        {"name":"Type ▸ Insert Special Character ▸ Markers ▸ Next Page Number", "key":"menu_action", "code": ["Type", "Insert Special Character", "Markers", "Next Page Number"]},
        {"name":"Type ▸ Insert Special Character ▸ Markers ▸ Previous Page Number", "key":"menu_action", "code": ["Type", "Insert Special Character", "Markers", "Previous Page Number"]},
        {"name":"Type ▸ Insert Special Character ▸ Markers ▸ Section Marker", "key":"menu_action", "code": ["Type", "Insert Special Character", "Markers", "Section Marker"]},
        {"name":"Type ▸ Insert Special Character ▸ Markers ▸ Footnote Number", "key":"menu_action", "code": ["Type", "Insert Special Character", "Markers", "Footnote Number"]},
        {"name":"Type ▸ Insert Special Character ▸ Hyphens and Dashes ▸ Em Dash", "key":"menu_action", "code": ["Type", "Insert Special Character", "Hyphens and Dashes", "Em Dash"]},
        {"name":"Type ▸ Insert Special Character ▸ Hyphens and Dashes ▸ En Dash", "key":"menu_action", "code": ["Type", "Insert Special Character", "Hyphens and Dashes", "En Dash"]},
        {"name":"Type ▸ Insert Special Character ▸ Hyphens and Dashes ▸ Discretionary Hyphen", "key":"menu_action", "code": ["Type", "Insert Special Character", "Hyphens and Dashes", "Discretionary Hyphen"]},
        {"name":"Type ▸ Insert Special Character ▸ Hyphens and Dashes ▸ Nonbreaking Hyphen", "key":"menu_action", "code": ["Type", "Insert Special Character", "Hyphens and Dashes", "Nonbreaking Hyphen"]},
        {"name":"Type ▸ Insert Special Character ▸ Quotation Marks ▸ Double Left Quotation Marks", "key":"menu_action", "code": ["Type", "Insert Special Character", "Quotation Marks", "Double Left Quotation Marks"]},
        {"name":"Type ▸ Insert Special Character ▸ Quotation Marks ▸ Double Right Quotation Marks", "key":"menu_action", "code": ["Type", "Insert Special Character", "Quotation Marks", "Double Right Quotation Marks"]},
        {"name":"Type ▸ Insert Special Character ▸ Quotation Marks ▸ Single Left Quotation Mark", "key":"menu_action", "code": ["Type", "Insert Special Character", "Quotation Marks", "Single Left Quotation Mark"]},
        {"name":"Type ▸ Insert Special Character ▸ Quotation Marks ▸ Single Right Quotation Mark", "key":"menu_action", "code": ["Type", "Insert Special Character", "Quotation Marks", "Single Right Quotation Mark"]},
        {"name":"Type ▸ Insert Special Character ▸ Quotation Marks ▸ Straight Double Quotation Marks", "key":"menu_action", "code": ["Type", "Insert Special Character", "Quotation Marks", "Straight Double Quotation Marks"]},
        {"name":"Type ▸ Insert Special Character ▸ Quotation Marks ▸ Straight Single Quotation Mark (Apostrophe)", "key":"menu_action", "code": ["Type", "Insert Special Character", "Quotation Marks", "Straight Single Quotation Mark (Apostrophe)"]},
        {"name":"Type ▸ Insert Special Character ▸ Other ▸ Tab", "key":"menu_action", "code": ["Type", "Insert Special Character", "Other", "Tab"]},
        {"name":"Type ▸ Insert Special Character ▸ Other ▸ Right Indent Tab", "key":"menu_action", "code": ["Type", "Insert Special Character", "Other", "Right Indent Tab"]},
        {"name":"Type ▸ Insert Special Character ▸ Other ▸ Indent to Here", "key":"menu_action", "code": ["Type", "Insert Special Character", "Other", "Indent to Here"]},
        {"name":"Type ▸ Insert Special Character ▸ Other ▸ End Nested Style Here", "key":"menu_action", "code": ["Type", "Insert Special Character", "Other", "End Nested Style Here"]},
        {"name":"Type ▸ Insert Special Character ▸ Other ▸ Non-joiner", "key":"menu_action", "code": ["Type", "Insert Special Character", "Other", "Non-joiner"]},
        {"name":"Type ▸ Insert White Space ▸ Em Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Em Space"]},
        {"name":"Type ▸ Insert White Space ▸ En Space", "key":"menu_action", "code": ["Type", "Insert White Space", "En Space"]},
        {"name":"Type ▸ Insert White Space ▸ Nonbreaking Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Nonbreaking Space"]},
        {"name":"Type ▸ Insert White Space ▸ Nonbreaking Space (Fixed Width)", "key":"menu_action", "code": ["Type", "Insert White Space", "Nonbreaking Space (Fixed Width)"]},
        {"name":"Type ▸ Insert White Space ▸ Hair Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Hair Space"]},
        {"name":"Type ▸ Insert White Space ▸ Sixth Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Sixth Space"]},
        {"name":"Type ▸ Insert White Space ▸ Thin Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Thin Space"]},
        {"name":"Type ▸ Insert White Space ▸ Quarter Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Quarter Space"]},
        {"name":"Type ▸ Insert White Space ▸ Third Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Third Space"]},
        {"name":"Type ▸ Insert White Space ▸ Punctuation Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Punctuation Space"]},
        {"name":"Type ▸ Insert White Space ▸ Figure Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Figure Space"]},
        {"name":"Type ▸ Insert White Space ▸ Flush Space", "key":"menu_action", "code": ["Type", "Insert White Space", "Flush Space"]},
        {"name":"Type ▸ Insert Break Character ▸ Column Break", "key":"menu_action", "code": ["Type", "Insert Break Character", "Column Break"]},
        {"name":"Type ▸ Insert Break Character ▸ Frame Break", "key":"menu_action", "code": ["Type", "Insert Break Character", "Frame Break"]},
        {"name":"Type ▸ Insert Break Character ▸ Page Break", "key":"menu_action", "code": ["Type", "Insert Break Character", "Page Break"]},
        {"name":"Type ▸ Insert Break Character ▸ Odd Page Break", "key":"menu_action", "code": ["Type", "Insert Break Character", "Odd Page Break"]},
        {"name":"Type ▸ Insert Break Character ▸ Even Page Break", "key":"menu_action", "code": ["Type", "Insert Break Character", "Even Page Break"]},
        {"name":"Type ▸ Insert Break Character ▸ Paragraph Return", "key":"menu_action", "code": ["Type", "Insert Break Character", "Paragraph Return"]},
        {"name":"Type ▸ Insert Break Character ▸ Forced Line Break", "key":"menu_action", "code": ["Type", "Insert Break Character", "Forced Line Break"]},
        {"name":"Type ▸ Insert Break Character ▸ Discretionary Line Break", "key":"menu_action", "code": ["Type", "Insert Break Character", "Discretionary Line Break"]},
        {"name":"Type ▸ Fill with Placeholder Text", "key":"menu_action", "code": ["Type", "Fill with Placeholder Text"]},
        {"name":"Type ▸ Show/Hide Hidden Characters", "key":"menu_action", "code": ["Type", "Hide|Show Hidden Characters"]},
        {"name":"Object ▸ Transform ▸ Move", "key":"menu_action", "code": ["Object", "Transform", "Move..."]},
        {"name":"Object ▸ Transform ▸ Scale", "key":"menu_action", "code": ["Object", "Transform", "Scale..."]},
        {"name":"Object ▸ Transform ▸ Rotate", "key":"menu_action", "code": ["Object", "Transform", "Rotate..."]},
        {"name":"Object ▸ Transform ▸ Shear", "key":"menu_action", "code": ["Object", "Transform", "Shear..."]},
        {"name":"Object ▸ Transform ▸ Rotate 90 CW", "key":"menu_action", "code": ["Object", "Transform", "Rotate 90 CW"]},
        {"name":"Object ▸ Transform ▸ Rotate 90 CCW", "key":"menu_action", "code": ["Object", "Transform", "Rotate 90 CCW"]},
        {"name":"Object ▸ Transform ▸ Rotate 180", "key":"menu_action", "code": ["Object", "Transform", "Rotate 180"]},
        {"name":"Object ▸ Transform ▸ Flip Horizontal", "key":"menu_action", "code": ["Object", "Transform", "Flip Horizontal"]},
        {"name":"Object ▸ Transform ▸ Flip Vertical", "key":"menu_action", "code": ["Object", "Transform", "Flip Vertical"]},
        {"name":"Object ▸ Transform ▸ Clear Transformations", "key":"menu_action", "code": ["Object", "Transform", "Clear Transformations"]},
        {"name":"Object ▸ Transform Again ▸ Transform Again", "key":"menu_action", "code": ["Object", "Transform Again", "Transform Again"]},
        {"name":"Object ▸ Transform Again ▸ Transform Again Individually", "key":"menu_action", "code": ["Object", "Transform Again", "Transform Again Individually"]},
        {"name":"Object ▸ Transform Again ▸ Transform Sequence Again", "key":"menu_action", "code": ["Object", "Transform Again", "Transform Sequence Again"]},
        {"name":"Object ▸ Transform Again ▸ Transform Sequence Again Individually", "key":"menu_action", "code": ["Object", "Transform Again", "Transform Sequence Again Individually"]},
        {"name":"Object ▸ Arrange ▸ Bring to Front", "key":"menu_action", "code": ["Object", "Arrange", "Bring to Front"]},
        {"name":"Object ▸ Arrange ▸ Bring Forward", "key":"menu_action", "code": ["Object", "Arrange", "Bring Forward"]},
        {"name":"Object ▸ Arrange ▸ Send Backward", "key":"menu_action", "code": ["Object", "Arrange", "Send Backward"]},
        {"name":"Object ▸ Arrange ▸ Send to Back", "key":"menu_action", "code": ["Object", "Arrange", "Send to Back"]},
        {"name":"Object ▸ Select ▸ First Object Above", "key":"menu_action", "code": ["Object", "Select", "First Object Above"]},
        {"name":"Object ▸ Select ▸ Next Object Above", "key":"menu_action", "code": ["Object", "Select", "Next Object Above"]},
        {"name":"Object ▸ Select ▸ Next Object Below", "key":"menu_action", "code": ["Object", "Select", "Next Object Below"]},
        {"name":"Object ▸ Select ▸ Last Object Below", "key":"menu_action", "code": ["Object", "Select", "Last Object Below"]},
        {"name":"Object ▸ Select ▸ Container", "key":"menu_action", "code": ["Object", "Select", "Container"]},
        {"name":"Object ▸ Select ▸ Content", "key":"menu_action", "code": ["Object", "Select", "Content"]},
        {"name":"Object ▸ Select ▸ Previous Object", "key":"menu_action", "code": ["Object", "Select", "Previous Object"]},
        {"name":"Object ▸ Select ▸ Next Object", "key":"menu_action", "code": ["Object", "Select", "Next Object"]},
        {"name":"Object ▸ Group", "key":"menu_action", "code": ["Object", "Group"]},
        {"name":"Object ▸ Ungroup", "key":"menu_action", "code": ["Object", "Ungroup"]},
        {"name":"Object ▸ Lock", "key":"menu_action", "code": ["Object", "Lock"]},
        {"name":"Object ▸ Unlock All on Spread", "key":"menu_action", "code": ["Object", "Unlock All on Spread"]},
        {"name":"Object ▸ Hide", "key":"menu_action", "code": ["Object", "Hide"]},
        {"name":"Object ▸ Show/Hide All on Spread", "key":"menu_action", "code": ["Object", "Show|Hide All on Spread"]},
        {"name":"Object ▸ Insert HTML", "key":"menu_action", "code": ["Object", "Insert HTML..."]},
        {"name":"Object ▸ Generate QR Code", "key":"menu_action", "code": ["Object", "Generate QR Code..."]},
        {"name":"Object ▸ Extract from Image ▸ Color Themes", "key":"menu_action", "code": ["Object", "Extract from Image", "Color Themes"]},
        {"name":"Object ▸ Extract from Image ▸ Shape", "key":"menu_action", "code": ["Object", "Extract from Image", "Shape"]},
        {"name":"Object ▸ Extract from Image ▸ Type", "key":"menu_action", "code": ["Object", "Extract from Image", "Type"]},
        {"name":"Object ▸ Text Frame Options", "key":"shortcut", "code": "^b"},
        {"name":"Object ▸ Anchored Object ▸ Options (Defaults)", "key":"menu_action", "code": ["Object", "Anchored Object", "Options (Defaults)..."]},
        {"name":"Object ▸ Anchored Object ▸ Insert (Defaults)", "key":"menu_action", "code": ["Object", "Anchored Object", "Insert (Defaults)..."]},
        {"name":"Object ▸ Anchored Object ▸ Release", "key":"menu_action", "code": ["Object", "Anchored Object", "Release"]},
        {"name":"Object ▸ Fitting ▸ Fill Frame Proportionally", "key":"menu_action", "code": ["Object", "Fitting", "Fill Frame Proportionally"]},
        {"name":"Object ▸ Fitting ▸ Fit Content Proportionally", "key":"menu_action", "code": ["Object", "Fitting", "Fit Content Proportionally"]},
        {"name":"Object ▸ Fitting ▸ Content-Aware Fit", "key":"menu_action", "code": ["Object", "Fitting", "Content-Aware Fit"]},
        {"name":"Object ▸ Fitting ▸ Fit Frame to Content", "key":"menu_action", "code": ["Object", "Fitting", "Fit Frame to Content"]},
        {"name":"Object ▸ Fitting ▸ Fit Content to Frame", "key":"menu_action", "code": ["Object", "Fitting", "Fit Content to Frame"]},
        {"name":"Object ▸ Fitting ▸ Center Content", "key":"menu_action", "code": ["Object", "Fitting", "Center Content"]},
        {"name":"Object ▸ Fitting ▸ Clear Frame Fitting Options", "key":"menu_action", "code": ["Object", "Fitting", "Clear Frame Fitting Options"]},
        {"name":"Object ▸ Fitting ▸ Frame Fitting Options", "key":"menu_action", "code": ["Object", "Fitting", "Frame Fitting Options..."]},
        {"name":"Object ▸ Content ▸ Graphic", "key":"menu_action", "code": ["Object", "Content", "Graphic"]},
        {"name":"Object ▸ Content ▸ Text", "key":"menu_action", "code": ["Object", "Content", "Text"]},
        {"name":"Object ▸ Content ▸ Unassigned", "key":"menu_action", "code": ["Object", "Content", "Unassigned"]},
        {"name":"Object ▸ Effects ▸ Transparency", "key":"menu_action", "code": ["Object", "Effects", "Transparency..."]},
        {"name":"Object ▸ Effects ▸ Drop Shadow...", "key":"menu_action", "code": ["Object", "Effects", "Drop Shadow..."]},
        {"name":"Object ▸ Effects ▸ Inner Shadow...", "key":"menu_action", "code": ["Object", "Effects", "Inner Shadow..."]},
        {"name":"Object ▸ Effects ▸ Outer Glow...", "key":"menu_action", "code": ["Object", "Effects", "Outer Glow..."]},
        {"name":"Object ▸ Effects ▸ Inner Glow...", "key":"menu_action", "code": ["Object", "Effects", "Inner Glow..."]},
        {"name":"Object ▸ Effects ▸ Bevel and Emboss...", "key":"menu_action", "code": ["Object", "Effects", "Bevel and Emboss..."]},
        {"name":"Object ▸ Effects ▸ Satin...", "key":"menu_action", "code": ["Object", "Effects", "Satin..."]},
        {"name":"Object ▸ Effects ▸ Basic Feather...", "key":"menu_action", "code": ["Object", "Effects", "Basic Feather..."]},
        {"name":"Object ▸ Effects ▸ Directional Feather...", "key":"menu_action", "code": ["Object", "Effects", "Directional Feather..."]},
        {"name":"Object ▸ Effects ▸ Gradient Feather...", "key":"menu_action", "code": ["Object", "Effects", "Gradient Feather..."]},
        {"name":"Object ▸ Effects ▸ Clear Effects", "key":"menu_action", "code": ["Object", "Effects", "Clear Effects"]},
        {"name":"Object ▸ Effects ▸ Clear All Transparency", "key":"menu_action", "code": ["Object", "Effects", "Clear All Transparency"]},
        {"name":"Object ▸ Effects ▸ Global Light...", "key":"menu_action", "code": ["Object", "Effects", "Global Light..."]},
        {"name":"Object ▸ Corner Options", "key":"menu_action", "code": ["Object", "Corner Options..."]},
        {"name":"Object ▸ Object Layer Options", "key":"menu_action", "code": ["Object", "Object Layer Options..."]},
        {"name":"Object ▸ Object Export Options", "key":"menu_action", "code": ["Object", "Object Export Options..."]},
        {"name":"Object ▸ Captions ▸ Caption Setup...", "key":"menu_action", "code": ["Object", "Captions", "Caption Setup..."]},
        {"name":"Object ▸ Captions ▸ Generate Live Caption", "key":"menu_action", "code": ["Object", "Captions", "Generate Live Caption"]},
        {"name":"Object ▸ Captions ▸ Generate Static Caption", "key":"menu_action", "code": ["Object", "Captions", "Generate Static Caption"]},
        {"name":"Object ▸ Clipping Path ▸ Options...", "key":"menu_action", "code": ["Object", "Clipping Path", "Options..."]},
        {"name":"Object ▸ Clipping Path ▸ Convert Clipping Path to Frame", "key":"menu_action", "code": ["Object", "Clipping Path", "Convert Clipping Path to Frame"]},
        {"name":"Object ▸ Image Color Settings", "key":"menu_action", "code": ["Object", "Image Color Settings..."]},
        {"name":"Object ▸ Interactive ▸ Convert to Button", "key":"menu_action", "code": ["Object", "Interactive", "Convert to Button"]},
        {"name":"Object ▸ Interactive ▸ Convert to Check Box", "key":"menu_action", "code": ["Object", "Interactive", "Convert to Check Box"]},
        {"name":"Object ▸ Interactive ▸ Convert to Combo Box", "key":"menu_action", "code": ["Object", "Interactive", "Convert to Combo Box"]},
        {"name":"Object ▸ Interactive ▸ Convert to List Box", "key":"menu_action", "code": ["Object", "Interactive", "Convert to List Box"]},
        {"name":"Object ▸ Interactive ▸ Convert to Radio Button", "key":"menu_action", "code": ["Object", "Interactive", "Convert to Radio Button"]},
        {"name":"Object ▸ Interactive ▸ Convert to Signature Field", "key":"menu_action", "code": ["Object", "Interactive", "Convert to Signature Field"]},
        {"name":"Object ▸ Interactive ▸ Convert to Text Field", "key":"menu_action", "code": ["Object", "Interactive", "Convert to Text Field"]},
        {"name":"Object ▸ Interactive ▸ Convert to Object", "key":"menu_action", "code": ["Object", "Interactive", "Convert to Object"]},
        {"name":"Object ▸ Interactive ▸ Convert to Motion Path", "key":"menu_action", "code": ["Object", "Interactive", "Convert to Motion Path"]},
        {"name":"Object ▸ Interactive ▸ Set Tab Order...", "key":"menu_action", "code": ["Object", "Interactive", "Set Tab Order..."]},
        {"name":"Object ▸ Paths ▸ Join", "key":"menu_action", "code": ["Object", "Paths", "Join"]},
        {"name":"Object ▸ Paths ▸ Open Path", "key":"menu_action", "code": ["Object", "Paths", "Open Path"]},
        {"name":"Object ▸ Paths ▸ Close Path", "key":"menu_action", "code": ["Object", "Paths", "Close Path"]},
        {"name":"Object ▸ Paths ▸ Reverse Path", "key":"menu_action", "code": ["Object", "Paths", "Reverse Path"]},
        {"name":"Object ▸ Paths ▸ Make Compound Path", "key":"menu_action", "code": ["Object", "Paths", "Make Compound Path"]},
        {"name":"Object ▸ Paths ▸ Release Compound Path", "key":"menu_action", "code": ["Object", "Paths", "Release Compound Path"]},
        {"name":"Object ▸ Pathfinder ▸ Add", "key":"menu_action", "code": ["Object", "Pathfinder", "Add"]},
        {"name":"Object ▸ Pathfinder ▸ Subtract", "key":"menu_action", "code": ["Object", "Pathfinder", "Subtract"]},
        {"name":"Object ▸ Pathfinder ▸ Intersect", "key":"menu_action", "code": ["Object", "Pathfinder", "Intersect"]},
        {"name":"Object ▸ Pathfinder ▸ Exclude Overlap", "key":"menu_action", "code": ["Object", "Pathfinder", "Exclude Overlap"]},
        {"name":"Object ▸ Pathfinder ▸ Minus Back", "key":"menu_action", "code": ["Object", "Pathfinder", "Minus Back"]},
        {"name":"Object ▸ Convert Shape ▸ Rectangle", "key":"menu_action", "code": ["Object", "Convert Shape", "Rectangle"]},
        {"name":"Object ▸ Convert Shape ▸ Rounded Rectangle", "key":"menu_action", "code": ["Object", "Convert Shape", "Rounded Rectangle"]},
        {"name":"Object ▸ Convert Shape ▸ Beveled Rectangle", "key":"menu_action", "code": ["Object", "Convert Shape", "Beveled Rectangle"]},
        {"name":"Object ▸ Convert Shape ▸ Inverse Rounded Rectangle", "key":"menu_action", "code": ["Object", "Convert Shape", "Inverse Rounded Rectangle"]},
        {"name":"Object ▸ Convert Shape ▸ Ellipse", "key":"menu_action", "code": ["Object", "Convert Shape", "Ellipse"]},
        {"name":"Object ▸ Convert Shape ▸ Triangle", "key":"menu_action", "code": ["Object", "Convert Shape", "Triangle"]},
        {"name":"Object ▸ Convert Shape ▸ Polygon", "key":"menu_action", "code": ["Object", "Convert Shape", "Polygon"]},
        {"name":"Object ▸ Convert Shape ▸ Line", "key":"menu_action", "code": ["Object", "Convert Shape", "Line"]},
        {"name":"Object ▸ Convert Shape ▸ Orthogonal Line", "key":"menu_action", "code": ["Object", "Convert Shape", "Orthogonal Line"]},
        {"name":"Object ▸ Convert Point ▸ Plain", "key":"menu_action", "code": ["Object", "Convert Point", "Plain"]},
        {"name":"Object ▸ Convert Point ▸ Corner", "key":"menu_action", "code": ["Object", "Convert Point", "Corner"]},
        {"name":"Object ▸ Convert Point ▸ Smooth", "key":"menu_action", "code": ["Object", "Convert Point", "Smooth"]},
        {"name":"Object ▸ Convert Point ▸ Symmetrical", "key":"menu_action", "code": ["Object", "Convert Point", "Symmetrical"]},
        {"name":"Object ▸ Display Performance ▸ Fast Display", "key":"menu_action", "code": ["Object", "Display Performance", "Fast Display"]},
        {"name":"Object ▸ Display Performance ▸ Typical Display", "key":"menu_action", "code": ["Object", "Display Performance", "Typical Display"]},
        {"name":"Object ▸ Display Performance ▸ High Quality Display", "key":"menu_action", "code": ["Object", "Display Performance", "High Quality Display"]},
        {"name":"Object ▸ Display Performance ▸ Use View Setting", "key":"menu_action", "code": ["Object", "Display Performance", "Use View Setting"]},
        {"name":"Table ▸ Create Table...", "key":"menu_action", "code": ["Table", "Create Table..."]},
        {"name":"Table ▸ Convert Text to Table...", "key":"menu_action", "code": ["Table", "Convert Text to Table..."]},
        {"name":"Table ▸ Convert Table to Text...", "key":"menu_action", "code": ["Table", "Convert Table to Text..."]},
        {"name":"Table ▸ Table Options ▸ Table Setup...", "key":"menu_action", "code": ["Table", "Table Options", "Table Setup..."]},
        {"name":"Table ▸ Table Options ▸ Alternating Row Strokes...", "key":"menu_action", "code": ["Table", "Table Options", "Alternating Row Strokes..."]},
        {"name":"Table ▸ Table Options ▸ Alternating Column Strokes...", "key":"menu_action", "code": ["Table", "Table Options", "Alternating Column Strokes..."]},
        {"name":"Table ▸ Table Options ▸ Alternating Fills...", "key":"menu_action", "code": ["Table", "Table Options", "Alternating Fills..."]},
        {"name":"Table ▸ Table Options ▸ Headers and Footers...", "key":"menu_action", "code": ["Table", "Table Options", "Headers and Footers..."]},
        {"name":"Table ▸ Cell Options ▸ Text...", "key":"menu_action", "code": ["Table", "Cell Options", "Text..."]},
        {"name":"Table ▸ Cell Options ▸ Graphic...", "key":"menu_action", "code": ["Table", "Cell Options", "Graphic..."]},
        {"name":"Table ▸ Cell Options ▸ Strokes and Fills...", "key":"menu_action", "code": ["Table", "Cell Options", "Strokes and Fills..."]},
        {"name":"Table ▸ Cell Options ▸ Rows and Columns...", "key":"menu_action", "code": ["Table", "Cell Options", "Rows and Columns..."]},
        {"name":"Table ▸ Cell Options ▸ Diagonal Lines...", "key":"menu_action", "code": ["Table", "Cell Options", "Diagonal Lines..."]},
        {"name":"Table ▸ Insert ▸ Row...", "key":"menu_action", "code": ["Table", "Insert", "Row..."]},
        {"name":"Table ▸ Insert ▸ Column...", "key":"menu_action", "code": ["Table", "Insert", "Column..."]},
        {"name":"Table ▸ Delete ▸ Row", "key":"menu_action", "code": ["Table", "Delete", "Row"]},
        {"name":"Table ▸ Delete ▸ Column", "key":"menu_action", "code": ["Table", "Delete", "Column"]},
        {"name":"Table ▸ Delete ▸ Table", "key":"menu_action", "code": ["Table", "Delete", "Table"]},
        {"name":"Table ▸ Select ▸ Cell", "key":"menu_action", "code": ["Table", "Select", "Cell"]},
        {"name":"Table ▸ Select ▸ Row", "key":"menu_action", "code": ["Table", "Select", "Row"]},
        {"name":"Table ▸ Select ▸ Column", "key":"menu_action", "code": ["Table", "Select", "Column"]},
        {"name":"Table ▸ Select ▸ Table", "key":"menu_action", "code": ["Table", "Select", "Table"]},
        {"name":"Table ▸ Select ▸ Header Rows", "key":"menu_action", "code": ["Table", "Select", "Header Rows"]},
        {"name":"Table ▸ Select ▸ Body Rows", "key":"menu_action", "code": ["Table", "Select", "Body Rows"]},
        {"name":"Table ▸ Select ▸ Footer Rows", "key":"menu_action", "code": ["Table", "Select", "Footer Rows"]}, 
        {"name":"Table ▸ Merge Cells", "key":"menu_action", "code": ["Table", "Merge Cells"]},
        {"name":"Table ▸ Unmerge Cells", "key":"menu_action", "code": ["Table", "Unmerge Cells"]},
        {"name":"Table ▸ Split Cell Horizontally", "key":"menu_action", "code": ["Table", "Split Cell Horizontally"]},
        {"name":"Table ▸ Split Cell Vertically", "key":"menu_action", "code": ["Table", "Split Cell Vertically"]},
        {"name":"Table ▸ Paste Before", "key":"menu_action", "code": ["Table", "Paste Before"]},
        {"name":"Table ▸ Paste After", "key":"menu_action", "code": ["Table", "Paste After"]},
        {"name":"Table ▸ Convert Cell to Graphic Cell", "key":"menu_action", "code": ["Table", "Convert Cell to Graphic Cell"]},
        {"name":"Table ▸ Convert Cell to Text Cell", "key":"menu_action", "code": ["Table", "Convert Cell to Text Cell"]},
        {"name":"Table ▸ Conver Rows ▸ To Header", "key":"menu_action", "code": ["Table", "Conver Rows", "To Header"]},
        {"name":"Table ▸ Conver Rows ▸ To Body", "key":"menu_action", "code": ["Table", "Conver Rows", "To Body"]},
        {"name":"Table ▸ Conver Rows ▸ To Footer", "key":"menu_action", "code": ["Table", "Conver Rows", "To Footer"]},
        {"name":"Table ▸ Distribute Rows Evenly", "key":"menu_action", "code": ["Table", "Distribute Rows Evenly"]},
        {"name":"Table ▸ Distribute Columns Evenly", "key":"menu_action", "code": ["Table", "Distribute Columns Evenly"]},
        {"name":"Table ▸ Go to Row...", "key":"menu_action", "code": ["Table", "Go to Row..."]},
        {"name":"Table ▸ Edit Header", "key":"menu_action", "code": ["Table", "Edit Header"]},
        {"name":"Table ▸ Edit Footer", "key":"menu_action", "code": ["Table", "Edit Footer"]},
        {"name":"View ▸ Zoom In", "key":"menu_action", "code": ["View", "Zoom In"]},
        {"name":"View ▸ Zoom Out", "key":"menu_action", "code": ["View", "Zoom Out"]},
        {"name":"View ▸ Fit Page in Window", "key":"menu_action", "code": ["View", "Fit Page in Window"]},
        {"name":"View ▸ Fit Spread in Window", "key":"menu_action", "code": ["View", "Fit Spread in Window"]},
        {"name":"View ▸ Actual Size", "key":"menu_action", "code": ["View", "Actual Size"]},
        {"name":"View ▸ Entire Pasteboard", "key":"menu_action", "code": ["View", "Entire Pasteboard"]},
        {"name":"View ▸ Match Pasteboard to Theme Color", "key":"menu_action", "code": ["View", "Match Pasteboard to Theme Color"]},
        {"name":"View ▸ Rotate Spread ▸ 90 CW", "key":"menu_action", "code": ["View", "Rotate Spread", "90 CW"]},
        {"name":"View ▸ Rotate Spread ▸ 90 CCW", "key":"menu_action", "code": ["View", "Rotate Spread", "90 CCW"]},
        {"name":"View ▸ Rotate Spread ▸ 180", "key":"menu_action", "code": ["View", "Rotate Spread", "180"]},
        {"name":"View ▸ Rotate Spread ▸ Clear Rotation", "key":"menu_action", "code": ["View", "Rotate Spread", "Clear Rotation"]},
        {"name":"View ▸ Screen Mode ▸ Normal", "key":"menu_action", "code": ["View", "Screen Mode", "Normal"]},
        {"name":"View ▸ Screen Mode ▸ Preview", "key":"menu_action", "code": ["View", "Screen Mode", "Preview"]},
        {"name":"View ▸ Screen Mode ▸ Bleed", "key":"menu_action", "code": ["View", "Screen Mode", "Bleed"]},
        {"name":"View ▸ Screen Mode ▸ Slug", "key":"menu_action", "code": ["View", "Screen Mode", "Slug"]},
        {"name":"View ▸ Screen Mode ▸ Presentation", "key":"menu_action", "code": ["View", "Screen Mode", "Presentation"]},
        {"name":"View ▸ Display Performance ▸ Fast Display", "key":"menu_action", "code": ["View", "Display Performance", "Fast Display"]},
        {"name":"View ▸ Display Performance ▸ Typical Display", "key":"menu_action", "code": ["View", "Display Performance", "Typical Display"]},
        {"name":"View ▸ Display Performance ▸ High Quality Display", "key":"menu_action", "code": ["View", "Display Performance", "High Quality Display"]},
        {"name":"View ▸ Display Performance ▸ Allow Object-Level Display Settings", "key":"menu_action", "code": ["View", "Display Performance", "Allow Object-Level Display Settings"]},
        {"name":"View ▸ Display Performance ▸ Clear Object-Level Display Settings", "key":"menu_action", "code": ["View", "Display Performance", "Clear Object-Level Display Settings"]},
        {"name":"View ▸ Show/Hide Rulers", "key":"menu_action", "code": ["View", "Show|Hide Rulers"]},
        {"name":"View ▸ Extras ▸ Show/Hide Frame Edges", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Frame Edges"]},
        {"name":"View ▸ Extras ▸ Show/Hide Text Threads", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Text Threads"]},
        {"name":"View ▸ Extras ▸ Show/Hide Assigned Frames", "key":"menu_action", "code": ["View", "Extras", "Hide|Show Assigned Frames"]},
        {"name":"View ▸ Extras ▸ Show/Hide Hyperlinks", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Hyperlinks"]},
        {"name":"View ▸ Extras ▸ Show/Hide Notes", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Notes"]},
        {"name":"View ▸ Extras ▸ Show/Hide Link Badge", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Link Badge"]},
        {"name":"View ▸ Extras ▸ Show/Hide Content Grabber", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Content Grabber"]},
        {"name":"View ▸ Extras ▸ Show/Hide Live Corners", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Live Corners"]},
        {"name":"View ▸ Extras ▸ Show/Hide Anchored Object Control", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Anchored Object Control"]},
        {"name":"View ▸ Extras ▸ Show/Hide Conveyor", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Conveyor"]},
        {"name":"View ▸ Extras ▸ Show/Hide Stock Purchase Badge", "key":"menu_action", "code": ["View", "Extras", "Show|Hide Stock Purchase Badge"]},
        {"name":"View ▸ Grids & Guides ▸ Show/Hide Guides", "key":"menu_action", "code": ["View", "Grids & Guides", "Show|Hide Guides"]},
        {"name":"View ▸ Grids & Guides ▸ Lock Guides", "key":"menu_action", "code": ["View", "Grids & Guides", "Lock Guides"]},
        {"name":"View ▸ Grids & Guides ▸ Lock Column Guides", "key":"menu_action", "code": ["View", "Grids & Guides", "Lock Column Guides"]},
        {"name":"View ▸ Grids & Guides ▸ Snap to Guides", "key":"menu_action", "code": ["View", "Grids & Guides", "Snap to Guides"]},
        {"name":"View ▸ Grids & Guides ▸ Smart Guides", "key":"menu_action", "code": ["View", "Grids & Guides", "Smart Guides"]},
        {"name":"View ▸ Grids & Guides ▸ Delete All Guides on Spread", "key":"menu_action", "code": ["View", "Grids & Guides", "Delete All Guides on Spread"]},
        {"name":"View ▸ Grids & Guides ▸ Show/Hide Baseline Grid", "key":"menu_action", "code": ["View", "Grids & Guides", "Show|Hide Baseline Grid"]},
        {"name":"View ▸ Grids & Guides ▸ Show/Hide Document Grid", "key":"menu_action", "code": ["View", "Grids & Guides", "Show|Hide Document Grid"]},
        {"name":"View ▸ Grids & Guides ▸ Snap to Document Grid", "key":"menu_action", "code": ["View", "Grids & Guides", "Snap to Document Grid"]},
        {"name":"View ▸ Structure ▸ Show/Hide Structure", "key":"menu_action", "code": ["View", "Structure", "Show|Hide Structure"]},
        {"name":"View ▸ Structure ▸ Show/Hide Tag Markers", "key":"menu_action", "code": ["View", "Structure", "Show|Hide Tag Markers"]},
        {"name":"View ▸ Structure ▸ Show/Hide Tagged Frames", "key":"menu_action", "code": ["View", "Structure", "Show|Hide Tagged Frames"]},
        {"name":"View ▸ Story Editor ▸ Show/Hide Changes", "key":"menu_action", "code": ["View", "Story Editor", "Show|Hide Changes"]},
        {"name":"View ▸ Story Editor ▸ Show/Hide Style Name Column", "key":"menu_action", "code": ["View", "Story Editor", "Show|Hide Style Name Column"]},
        {"name":"View ▸ Story Editor ▸ Show/Hide Depth Ruler", "key":"menu_action", "code": ["View", "Story Editor", "Show|Hide Depth Ruler"]},
        {"name":"View ▸ Story Editor ▸ Show/Hide Paragraph Break Marks", "key":"menu_action", "code": ["View", "Story Editor", "Show|Hide Paragraph Break Marks"]},
        {"name":"View ▸ Story Editor ▸ Expand All Footnotes", "key":"menu_action", "code": ["View", "Story Editor", "Expand All Footnotes"]},
        {"name":"Plug-Ins ▸ Plugins Panel", "key":"menu_action", "code": ["Plug-Ins", "Plugins Panel"]},
        {"name":"Plug-Ins ▸ Browse Plugins", "key":"menu_action", "code": ["Plug-Ins", "Browse Plugins"]},
        {"name":"Plug-Ins ▸ Manage Plugins", "key":"menu_action", "code": ["Plug-Ins", "Manage Plugins"]},
        {"name":"Window ▸ Arrange ▸ Tile All Vertically", "key":"menu_action", "code": ["Window", "Arrange", "Tile All Vertically"]},
        {"name":"Window ▸ Arrange ▸ Tile All Horizontally", "key":"menu_action", "code": ["Window", "Arrange", "Tile All Horizontally"]},
        {"name":"Window ▸ Arrange ▸ 2-up Horizontal", "key":"menu_action", "code": ["Window", "Arrange", "2-up Horizontal"]},
        {"name":"Window ▸ Arrange ▸ 2-up Vertical", "key":"menu_action", "code": ["Window", "Arrange", "2-up Vertical"]},
        {"name":"Window ▸ Arrange ▸ 3-up Horizontal", "key":"menu_action", "code": ["Window", "Arrange", "3-up Horizontal"]},
        {"name":"Window ▸ Arrange ▸ 3-up Vertical", "key":"menu_action", "code": ["Window", "Arrange", "3-up Vertical"]},
        {"name":"Window ▸ Arrange ▸ 3-up Stacked", "key":"menu_action", "code": ["Window", "Arrange", "3-up Stacked"]},
        {"name":"Window ▸ Arrange ▸ 4-up", "key":"menu_action", "code": ["Window", "Arrange", "4-up"]},
        {"name":"Window ▸ Arrange ▸ 4-up Vertical", "key":"menu_action", "code": ["Window", "Arrange", "4-up Vertical"]},
        {"name":"Window ▸ Arrange ▸ 4-up Horizontal", "key":"menu_action", "code": ["Window", "Arrange", "4-up Horizontal"]},
        {"name":"Window ▸ Arrange ▸ 4-up Stacked", "key":"menu_action", "code": ["Window", "Arrange", "4-up Stacked"]},
        {"name":"Window ▸ Arrange ▸ 5-up Stacked", "key":"menu_action", "code": ["Window", "Arrange", "5-up Stacked"]},
        {"name":"Window ▸ Arrange ▸ 6-up", "key":"menu_action", "code": ["Window", "Arrange", "6-up"]},
        {"name":"Window ▸ Arrange ▸ 6-up Stacked", "key":"menu_action", "code": ["Window", "Arrange", "6-up Stacked"]},
        {"name":"Window ▸ Arrange ▸ Cascade", "key":"menu_action", "code": ["Window", "Arrange", "Cascade"]},
        {"name":"Window ▸ Arrange ▸ Tile", "key":"menu_action", "code": ["Window", "Arrange", "Tile"]},
        {"name":"Window ▸ Arrange ▸ Float in Window", "key":"menu_action", "code": ["Window", "Arrange", "Float in Window"]},
        {"name":"Window ▸ Arrange ▸ Float All in Windows", "key":"menu_action", "code": ["Window", "Arrange", "Float All in Windows"]},
        {"name":"Window ▸ Arrange ▸ Consolidate All Windows", "key":"menu_action", "code": ["Window", "Arrange", "Consolidate All Windows"]},
        {"name":"Window ▸ Arrange ▸ Split Window", "key":"menu_action", "code": ["Window", "Arrange", "Split Window"]},
        {"name":"Window ▸ Arrange ▸ Minimize", "key":"menu_action", "code": ["Window", "Arrange", "Minimize"]},
        {"name":"Window ▸ Arrange ▸ Bring All to Front", "key":"menu_action", "code": ["Window", "Arrange", "Bring All to Front"]},
        {"name":"Window ▸ Find Extensions on Exchange...", "key":"menu_action", "code": ["Window", "Find Extensions on Exchange..."]},
        {"name":"Window ▸ Articles", "key":"menu_action", "code": ["Window", "Articles"]},
        {"name":"Window ▸ CC Libraries", "key":"menu_action", "code": ["Window", "CC Libraries"]},
        {"name":"Window ▸ Color ▸ Color", "key":"menu_action", "code": ["Window", "Color", "Color"]},
        {"name":"Window ▸ Color ▸ Gradient", "key":"menu_action", "code": ["Window", "Color", "Gradient"]},
        {"name":"Window ▸ Color ▸ Swatches", "key":"menu_action", "code": ["Window", "Color", "Swatches"]},
        {"name":"Window ▸ Comments ▸ PDF Comments", "key":"menu_action", "code": ["Window", "Comments", "PDF Comments"]},
        {"name":"Window ▸ Comments ▸ Review", "key":"menu_action", "code": ["Window", "Comments", "Review"]},
        {"name":"Window ▸ Control", "key":"menu_action", "code": ["Window", "Control"]},
        {"name":"Window ▸ Editorial ▸ Assignments", "key":"menu_action", "code": ["Window", "Editorial", "Assignments"]},
        {"name":"Window ▸ Editorial ▸ Notes", "key":"menu_action", "code": ["Window", "Editorial", "Notes"]},
        {"name":"Window ▸ Editorial ▸ Track Changes", "key":"menu_action", "code": ["Window", "Editorial", "Track Changes"]},
        {"name":"Window ▸ Effects", "key":"menu_action", "code": ["Window", "Effects"]},
        {"name":"Window ▸ Info", "key":"menu_action", "code": ["Window", "Info"]},
        {"name":"Window ▸ Interactive ▸ Animation", "key":"menu_action", "code": ["Window", "Interactive", "Animation"]},
        {"name":"Window ▸ Interactive ▸ Bookmarks", "key":"menu_action", "code": ["Window", "Interactive", "Bookmarks"]},
        {"name":"Window ▸ Interactive ▸ Buttons and Forms", "key":"menu_action", "code": ["Window", "Interactive", "Buttons and Forms"]},
        {"name":"Window ▸ Interactive ▸ EPUB Interactivity Preview", "key":"menu_action", "code": ["Window", "Interactive", "EPUB Interactivity Preview"]},
        {"name":"Window ▸ Interactive ▸ Hyperlinks", "key":"menu_action", "code": ["Window", "Interactive", "Hyperlinks"]},
        {"name":"Window ▸ Interactive ▸ Liquid Layout", "key":"menu_action", "code": ["Window", "Interactive", "Liquid Layout"]},
        {"name":"Window ▸ Interactive ▸ Media", "key":"menu_action", "code": ["Window", "Interactive", "Media"]},
        {"name":"Window ▸ Interactive ▸ Object States", "key":"menu_action", "code": ["Window", "Interactive", "Object States"]},
        {"name":"Window ▸ Interactive ▸ Page Transitions", "key":"menu_action", "code": ["Window", "Interactive", "Page Transitions"]},
        {"name":"Window ▸ Interactive ▸ Timing", "key":"menu_action", "code": ["Window", "Interactive", "Timing"]},
        {"name":"Window ▸ Layers", "key":"menu_action", "code": ["Window", "Layers"]},
        {"name":"Window ▸ Learn", "key":"menu_action", "code": ["Window", "Learn"]},
        {"name":"Window ▸ Links", "key":"menu_action", "code": ["Window", "Links"]},
        {"name":"Window ▸ Object & Layout ▸ Align", "key":"menu_action", "code": ["Window", "Object & Layout", "Align"]},
        {"name":"Window ▸ Object & Layout ▸ Pathfinder", "key":"menu_action", "code": ["Window", "Object & Layout", "Pathfinder"]},
        {"name":"Window ▸ Object & Layout ▸ Transform", "key":"menu_action", "code": ["Window", "Object & Layout", "Transform"]},
        {"name":"Window ▸ Output ▸ Attributes", "key":"menu_action", "code": ["Window", "Output", "Attributes"]},
        {"name":"Window ▸ Output ▸ Flattener Preview", "key":"menu_action", "code": ["Window", "Output", "Flattener Preview"]},
        {"name":"Window ▸ Output ▸ Preflight", "key":"menu_action", "code": ["Window", "Output", "Preflight"]},
        {"name":"Window ▸ Output ▸ Separations Preview", "key":"menu_action", "code": ["Window", "Output", "Separations Preview"]},
        {"name":"Window ▸ Output ▸ Trap Presets", "key":"menu_action", "code": ["Window", "Output", "Trap Presets"]},
        {"name":"Window ▸ Overlays", "key":"menu_action", "code": ["Window", "Overlays"]},
        {"name":"Window ▸ Pages", "key":"menu_action", "code": ["Window", "Pages"]},
        {"name":"Window ▸ Properties", "key":"menu_action", "code": ["Window", "Properties"]},
        {"name":"Window ▸ Stroke", "key":"menu_action", "code": ["Window", "Stroke"]},
        {"name":"Window ▸ Styles ▸ Cell Styles", "key":"menu_action", "code": ["Window", "Styles", "Cell Styles"]},
        {"name":"Window ▸ Styles ▸ Character Styles", "key":"shortcut", "code": "+{F11}"},
        {"name":"Window ▸ Styles ▸ Object Styles", "key":"menu_action", "code": ["Window", "Styles", "Object Styles"]},
        {"name":"Window ▸ Styles ▸ Paragraph Styles", "key":"shortcut", "code": "{F11}"},
        {"name":"Window ▸ Styles ▸ Table Styles", "key":"menu_action", "code": ["Window", "Styles", "Table Styles"]},
        {"name":"Window ▸ Text Wrap", "key":"menu_action", "code": ["Window", "Text Wrap"]},
        {"name":"Window ▸ Tools", "key":"menu_action", "code": ["Window", "Tools"]},
        {"name":"Window ▸ Type & Tables ▸ Character", "key":"menu_action", "code": ["Window", "Type & Tables", "Character"]},
        {"name":"Window ▸ Type & Tables ▸ Conditional Text", "key":"menu_action", "code": ["Window", "Type & Tables", "Conditional Text"]},
        {"name":"Window ▸ Type & Tables ▸ Cross-References", "key":"menu_action", "code": ["Window", "Type & Tables", "Cross-References"]},
        {"name":"Window ▸ Type & Tables ▸ Glyphs", "key":"menu_action", "code": ["Window", "Type & Tables", "Glyphs"]},
        {"name":"Window ▸ Type & Tables ▸ Index", "key":"menu_action", "code": ["Window", "Type & Tables", "Index"]},
        {"name":"Window ▸ Type & Tables ▸ Paragraph", "key":"menu_action", "code": ["Window", "Type & Tables", "Paragraph"]},
        {"name":"Window ▸ Type & Tables ▸ Story", "key":"menu_action", "code": ["Window", "Type & Tables", "Story"]},
        {"name":"Window ▸ Type & Tables ▸ Table", "key":"menu_action", "code": ["Window", "Type & Tables", "Table"]},
        {"name":"Window ▸ Utilities ▸ Background Tasks", "key":"menu_action", "code": ["Window", "Utilities", "Background Tasks"]},
        {"name":"Window ▸ Utilities ▸ Data Merge", "key":"menu_action", "code": ["Window", "Utilities", "Data Merge"]},
        {"name":"Window ▸ Utilities ▸ Script Label", "key":"menu_action", "code": ["Window", "Utilities", "Script Label"]},
        {"name":"Window ▸ Utilities ▸ Scripts", "key":"menu_action", "code": ["Window", "Utilities", "Scripts"]},
        {"name":"Window ▸ Utilities ▸ Tags", "key":"menu_action", "code": ["Window", "Utilities", "Tags"]},
        {"name":"Window ▸ Utilities ▸ Tool Hints", "key":"menu_action", "code": ["Window", "Utilities", "Tool Hints"]},
        {"name":"Window ▸ Application Frame", "key":"menu_action", "code": ["Window", "Application Frame"]}
    ];

    let temp = _.map(commands, (x)=>{return {...x, uid:uuid()}});
    return temp
}