import React, { useEffect, useState, useLayoutEffect, useRef, useDispatch, useSelector } from 'react';

//sm, md, lg, xl
const Breakpoints = {
    small: { min: 0, max: 600 },
    medium: { min: 601, max: 900 },
    large: { min: 901, max: 1200 },
    huge: { min: 1201, max: 1410 }
};

const ScreenType = {
    gird4: 0,
    grid8: 1,
    grid12: 2,
    grid16: 3,
    unknown: 4
} 

export const calc = function (maxWidth, margin, gutter) {
    let lt = {};
    let width = Breakpoints.small.min;

    if (maxWidth <= Breakpoints.huge.max) {
        width = maxWidth;
    } else {
        width = Breakpoints.huge.max;
    }

    lt.margin = margin;
    lt.col = 60;
    lt.gutter = gutter/2;
    let type = getScreenType(width);
    if (type === ScreenType.gird4) {
        lt.max = 2;
        lt.containerWidth = width;
    } else if (type === ScreenType.grid8) {
        lt.max = 4;
        lt.containerWidth = width;
    } else if (type === ScreenType.grid12) {
        lt.max = 6;
        lt.containerWidth = width;
    } else if (type === ScreenType.grid16) {
        lt.max = 8;
        lt.containerWidth = width;
    } else {
        return lt;
    }

    let half = lt.containerWidth / 2;
    let total = (lt.col + lt.gutter + lt.margin) * lt.max;
    let adj = (total - half) / lt.max;
    lt.col -= adj;
    lt.max *= 2;

    lt.containerWidth = (lt.col + lt.gutter + lt.margin) * lt.max;
    lt.innerWidth = lt.containerWidth - lt.margin * 2;
    lt.gutter *= 2;
    adj = (lt.containerWidth-(lt.col*lt.max+lt.gutter*(lt.max-1)+lt.margin*2))/lt.max;
    lt.col += adj;
    lt.bunch1 = lt.col;
    lt.bunch2 = lt.bunch1 + (lt.col + lt.gutter);
    lt.bunch3 = lt.bunch2 + (lt.col + lt.gutter);
    lt.bunch4 = lt.bunch3 + (lt.col + lt.gutter);
    lt.bunch5 = lt.bunch4 + (lt.col + lt.gutter);
    lt.bunch6 = lt.bunch5 + (lt.col + lt.gutter);
    lt.bunch7 = lt.bunch6 + (lt.col + lt.gutter);
    lt.bunch8 = lt.bunch7 + (lt.col + lt.gutter);
    lt.bunch9 = lt.bunch8 + (lt.col + lt.gutter);
    lt.bunch10 = lt.bunch9 + (lt.col + lt.gutter);
    lt.bunch11 = lt.bunch10 + (lt.col + lt.gutter);
    lt.bunch12 = lt.bunch11 + (lt.col + lt.gutter);
    lt.bunch13 = lt.bunch12 + (lt.col + lt.gutter);
    lt.bunch14 = lt.bunch13 + (lt.col + lt.gutter);
    lt.bunch15 = lt.bunch14 + (lt.col + lt.gutter);
    lt.bunch16 = lt.bunch15 + (lt.col + lt.gutter);

    lt.space0 = lt.margin;
    lt.space1 = lt.space0 + (lt.col + lt.gutter);
    lt.space2 = lt.space1 + (lt.col + lt.gutter);
    lt.space3 = lt.space2 + (lt.col + lt.gutter);
    lt.space4 = lt.space3 + (lt.col + lt.gutter);
    lt.space5 = lt.space4 + (lt.col + lt.gutter);
    lt.space6 = lt.space5 + (lt.col + lt.gutter);
    lt.space7 = lt.space6 + (lt.col + lt.gutter);
    lt.space8 = lt.space7 + (lt.col + lt.gutter);
    lt.space9 = lt.space8 + (lt.col + lt.gutter);
    lt.space10 = lt.space9 + (lt.col + lt.gutter);
    lt.space11 = lt.space10 + (lt.col + lt.gutter);
    lt.space12 = lt.space11 + (lt.col + lt.gutter);
    lt.space13 = lt.space12 + (lt.col + lt.gutter);
    lt.space14 = lt.space13 + (lt.col + lt.gutter);
    lt.space15 = lt.space14 + (lt.col + lt.gutter);

    return lt;
}

function getScreenType(width) {
    if (width >= Breakpoints.small.min && width <= Breakpoints.small.max) {
        return ScreenType.gird4;
    } else if (width > Breakpoints.medium.min && width <= Breakpoints.medium.max) {
        return ScreenType.grid8;
    } else if (width > Breakpoints.medium.min && width <= Breakpoints.large.max) {
        return ScreenType.grid12;
    } else if (width > Breakpoints.huge.min && width <= Breakpoints.huge.max) {
        return ScreenType.grid16;
    } else {
        return ScreenType.unknown;
    }
}

export const Axis = {
    main: {
        start: "flex-start",
        end: "flex-end",
        center: "center",
        between: "space-between",
        around: "space-around",
        evenly: "space-evenly",
        initial: "initial",
        inherit: "inherit",
    },
    cross: {
        stretch: "stretch",
        start: "flex-start",
        end: "flex-end",
        center: "center",
        baseline: "baseline",
        initial: "initial",
        inherit: "inherit",
    },
    self: {
        auto: "auto",
        initial: "initial",
        inherit: "inherit",
        stretch: "stretch",
        start: "flex-start",
        end: "flex-end",
        center: "center",
        baseline: "baseline"
    }
}

export function Row(props) {
    const filterProps = ["debug", "center", "top", "left", "right", "bottom", "width", "height", "color", "main", "cross", "self", "sx", "leftOffset", "rightOffset", "topOffset", "bottomOffset"];
    let style = {
        display: 'flex',
        flexDirection: 'row',
    };
    if (props.width) {
        style.width = props.width;
    }
    if (props.height) {
        style.height = props.height;
    }
    if (props.color) {
        style.backgroundColor = props.color;
    }
    if (props.center) {
        style.justifyContent = 'center';
        style.alignItems = 'center';
    }
    if (props.main) {
        style.justifyContent = props.main;
    }
    if (props.cross) {
        style.alignItems = props.cross;
    }
    if (props.self) {
        style.alignSelf = props.self;
    }
    if (props.top) {
        style.marginTop = props.top;
    }
    if (props.right) {
        style.marginRight = props.right;
    }
    if (props.left) {
        style.marginLeft = props.left;
    }
    if (props.bottom) {
        style.marginBottom = props.bottom;
    }
    if (props.topOffset) {
        style.paddingTop = props.topOffset;
    }
    if (props.rightOffset) {
        style.paddingRight = props.rightOffset;
    }
    if (props.leftOffset) {
        style.paddingLeft = props.leftOffset;
    }
    if (props.bottomOffset) {
        style.paddingBottom = props.bottomOffset;
    }
    if (props.debug) {
        /*style.borderStyle = 'dashed';
        style.borderColor = 'rgb(163, 134, 113)';
        style.borderWidth = 2;
        style.boxSizing = 'border-box';*/
        style.boxShadow ="0px 0px 0px 1px green inset";
        
    };
    style = {...style, ...props.sx};

    let copyProps = {...props};
    for (let item of filterProps) {
        if (copyProps.hasOwnProperty(item)) {
        delete copyProps[item];
        }
    }
    
    return (
        <div style={style} {...copyProps}>
        {props.children}
        </div>
    );
}


export function Column(props) {
    const filterProps = ["debug", "center", "top", "left", "right", "bottom", "width", "height", "color", "main", "cross", "self", "sx" , "leftOffset", "rightOffset", "topOffset", "bottomOffset"];
    let style = {
        display: 'flex',
        flexDirection: 'column',
    };
    if (props.width) {
        style.width = props.width;
    }
    if (props.height) {
        style.height = props.height;
    }
    if (props.color) {
        style.backgroundColor = props.color;
    }
    if (props.center) {
        style.justifyContent = 'center';
        style.alignItems = 'center';
    }
    if (props.main) {
        style.justifyContent = props.main;
    }
    if (props.cross) {
        style.alignItems = props.cross;
    }
    if (props.self) {
        style.alignSelf = props.self;
    }
    if (props.top) {
        style.marginTop = props.top;
    }
    if (props.right) {
        style.marginRight = props.right;
    }
    if (props.left) {
        style.marginLeft = props.left;
    }
    if (props.bottom) {
        style.marginBottom = props.bottom;
    }
    if (props.topOffset) {
        style.paddingTop = props.topOffset;
    }
    if (props.rightOffset) {
        style.paddingRight = props.rightOffset;
    }
    if (props.leftOffset) {
        style.paddingLeft = props.leftOffset;
    }
    if (props.bottomOffset) {
        style.paddingBottom = props.bottomOffset;
    }
    if (props.debug) {
        /*style.borderStyle = 'dashed';
        style.borderColor = 'rgb(163, 134, 113)';
        style.borderWidth = 2;
        style.boxSizing = 'border-box';*/
        style.boxShadow ="0px 0px 0px 1px yellow inset";
    };
    style = {...style, ...props.sx};

    let copyProps = {...props};
    //console.log(copyProps);
    for (let item of filterProps) {
        if (copyProps.hasOwnProperty(item)) {
        delete copyProps[item];
        } 
    }
    
    return (
        <div style={style} {...copyProps}>
        {props.children}
        </div>
    );
}

export function Layer(props) {
    const $ = window.$;
    const ref = useRef(0);

    useLayoutEffect(()=>{
        let w = $(ref.current).parent().width();
        let h = $(ref.current).parent().height();
        if (props.width !== undefined) {
            w = props.width;
        }
        if (props.height !== undefined) {
            h = props.height;
        }
        $(ref.current).css({width:w, height:h});
    },[])

    let style = {
        position:'absolute'
    }
    if (props.debug) {
        style.borderStyle = 'dashed';
        style.borderColor = 'rgb(163, 134, 113)';
        style.borderWidth = 2;
        style.boxSizing = 'border-box';
    };
    if (props.hide) {
        style.visibility="hidden";
    } else {
        style.visibility="visible";
    }
    style = {...style, ...props.sx};
    return(
        <div style={style} ref={ref}>{props.children}</div>
    )
}


export function Debug(props) {
    const lt = props.lt;
    const [height, setHeight] = useState(20);

    let body = [];
    for (let i = 0; i < lt.max; i++) {
        body.push(
            <Column center key={i} width={lt.col + lt.gutter} height={height} color={'rgba(80, 102, 161, 0.3)'} sx={{ transition: "height 0.3s ease-in-out" }}>
                <Column width={lt.col} height={height} color={'rgba(240, 113, 121, 0.3)'} sx={{ transition: "height 0.3s ease-in-out" }}></Column>
            </Column>
        );
    }

    const handleClick = () => {
        if (height === 20) {
            setHeight(window.document.documentElement.scrollHeight);
        } else {
            setHeight(20);
        }
    }

    return(
        <>
        { props.hide ?
            <></>
        :
            <Row main={Axis.main.center} onClick={handleClick}>
                {/*<Row width={lt.margin} color={"red"}></Row>*/}
                <Row center>{body}</Row>
                {/*<Row width={lt.margin} color={"red"}></Row>*/}
            </Row>
        }
        </>
    )
}
