import axios from 'axios';
import _, { shuffle } from 'lodash';


import { 
    SET_CALCULATED_LAYOUT, 
    RESET_STATE,
    GET_LAYOUTS,
    GET_LAYOUT_INFO,
    GET_COLORS,
    GET_FONTS,
    GET_PAPERS,
} from "./types";


export const getLayouts = (page, search) => async (dispatch, getState) => {
    try {
      const state = getState().mainState;
      let headers = { 'Content-Type': `application/json`, 'Store':state.store_view_code};
      let query = `
      {
        products(filter: {category_uid: {eq:"NA=="}}, search:"${search}", pageSize:10, currentPage:${page}, sort: {relevance: ASC}) {
          items {
            name
            sku
            indesign_file
            media_gallery {
              url
              position
            }
            image {
              url
              label
            }
            thumbnail {
                url
            }
            description {
              html
            }
            short_description {
              html
            }
            categories {
              name
              uid
              level
              breadcrumbs {
                category_level
                category_name
                category_uid
              }
            }
            related_products {
              name
              uid
              short_description {
                html
              }
              thumbnail {
                url
                position
              }
              image {
                url
                label
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
          total_count
        }
      }
      `;
      let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      if (result.data.hasOwnProperty("errors")) {
      } else {
        dispatch({
          type: GET_LAYOUTS,
          payload: {
            layouts: result.data
          }
        })
      }
    } catch (error) {
      console.log(error);
    }
}

export const getColors = (page, callback) => async (dispatch, getState) => {
    try {
      const state = getState().mainState;
      let headers = { 'Content-Type': `application/json`};
      let query = `
      {
        products(filter: {category_uid: {eq:"MzY="}}, pageSize:10, currentPage:${page}, sort: {relevance: ASC}) {
          items {
            name
            sku
            color1
            color2
            color3
            media_gallery {
              url
              position
            }
            image {
              url
              label
            }
            thumbnail {
                url
            }
            description {
              html
            }
            short_description {
              html
            }
            categories {
              name
              uid
              level
              breadcrumbs {
                category_level
                category_name
                category_uid
              }
            }
            related_products {
              name
              uid
              short_description {
                html
              }
              thumbnail {
                url
                position
              }
              image {
                url
                label
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
          total_count
        }
      }
      `;
      let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      if (result.data.hasOwnProperty("errors")) {
      } else {
        /*dispatch({
          type: GET_COLORS,
          payload: {
            colors: result.data
          }
        });*/
        callback(result.data);
      }
    } catch (error) {
      console.log(error);
    }
}

export const getFonts = (page, callback) => async (dispatch, getState) => {
    try {
      const state = getState().mainState;
      let headers = { 'Content-Type': `application/json`, 'Store':state.store_view_code};
      let query = `
      {
        products(filter: {category_uid: {eq:"MzQ="}}, pageSize:10, currentPage:${page}, sort: {relevance: ASC}) {
          items {
            name
            sku
            font1
            font2
            font3
            media_gallery {
              url
              position
            }
            image {
              url
              label
            }
            thumbnail {
                url
            }
            description {
              html
            }
            short_description {
              html
            }
            categories {
              name
              uid
              level
              breadcrumbs {
                category_level
                category_name
                category_uid
              }
            }
            related_products {
              name
              uid
              short_description {
                html
              }
              thumbnail {
                url
                position
              }
              image {
                url
                label
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
          total_count
        }
      }
      `;
      let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      if (result.data.hasOwnProperty("errors")) {
      } else {
        /*dispatch({
          type: GET_FONTS,
          payload: {
            fonts: result.data
          }
        });*/
        callback(result.data);
      }
    } catch (error) {
      console.log(error);
    }
}

export const getPapers = (page, callback) => async (dispatch, getState) => {
    try {
      const state = getState().mainState;
      let headers = { 'Content-Type': `application/json`, 'Store':state.store_view_code};
      let query = `
      {
        products(filter: {category_uid: {eq:"MzU="}}, pageSize:10, currentPage:${page}, sort: {relevance: ASC}) {
          items {
            name
            sku
            paper_thickness
            media_gallery {
              url
              position
            }
            image {
              url
              label
            }
            thumbnail {
                url
            }
            description {
              html
            }
            short_description {
              html
            }
            categories {
              name
              uid
              level
              breadcrumbs {
                category_level
                category_name
                category_uid
              }
            }
            related_products {
              name
              uid
              short_description {
                html
              }
              thumbnail {
                url
                position
              }
              image {
                url
                label
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
          total_count
        }
      }
      `;
      let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      if (result.data.hasOwnProperty("errors")) {
      } else {
        /*dispatch({
          type: GET_PAPERS,
          payload: {
            papers: result.data
          }
        });*/
        callback(result.data);
      }
    } catch (error) {
      console.log(error);
    }
}

export const getLayoutInfo = (sku) => async (dispatch, getState) => {
    try {
      const state = getState().mainState;
      //let headers = { 'Authorization': `Bearer ${customerToken}`};
      let headers = { 'Content-Type': `application/json`, 'Store':state.store_view_code};
      let query = 
      `{
          products(filter: {sku: {eq:"${sku}"}}) {
              items {
                  name
                  sku
                  indesign_file
                  book_type
                  media_gallery {
                      url
                      position
                  }
                  image {
                      url
                      label
                  }
                  description {
                      html
                  }
                  short_description {
                      html
                  }
                  categories {
                    name
                    uid
                    level
                    breadcrumbs {
                      category_level
                      category_name
                      category_uid
                    }
                  }
              }
          } 
      }`;
      let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      //console.log(result.data);
      if (result.data.hasOwnProperty("errors")) {
      } else {
          let info = result.data.data.products.items[0];
          let filtered = _.filter(info.media_gallery, x => x.url.includes("thumb-") === false);
          info.media_gallery = [...filtered];
          dispatch({
              type: GET_LAYOUT_INFO,
              payload: {
                  productInfo: info
              }
          })
      }
    } catch (error) {
          console.log(error);
    }
}

export const isTokenValid = (token, callback) => async (dispatch, getState) => {
    try {
        const state = getState().mainState;
        let headers = { 'Content-Type': `application/json`, 'Authorization': `Bearer ${token}`};
        let query = `
        query {isTokenValid {
            valid
          }
        }
        `;
        let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
        //console.log(result.data);
        if (result.data.hasOwnProperty("errors")) {
            callback(false);
        } else {
            callback(result.data.data.isTokenValid.valid);
        }      
    } catch (error) {
      console.log(error);
      callback(false);
    }
}

export const isTokenValid2 = (token, callback) => async (dispatch, getState) => {
  try {
    const state = getState().mainState;
    let headers = { 'Content-Type': `application/json`, 'Authorization': `Bearer ${token}`};
    let query = `
    query {isTokenValid {
        valid
      }
    }
    `;
    let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
    //console.log(result.data);
    if (result.data.hasOwnProperty("errors")) {
      window.host().postMessage({command:"set_customer_token", data:null});
      window.host().postMessage({command:"set_customer_data", data:null});
      callback(false);
    } else {
      if (!result.data.data.isTokenValid.valid) {
        state.getCustomerData().then((data)=>{
          if (data !== null) {
            //console.log(data);
            let emailText = data.email;
            let passwordText = data.password;
            dispatch(generateCustomerToken(emailText, passwordText, (data)=>{
              if (data.hasOwnProperty("error")) {
                window.host().postMessage({command:"set_customer_token", data:null});
                window.host().postMessage({command:"set_customer_data", data:null});
                callback(false);
                } else {
                window.host().postMessage({command:"set_customer_token", data:data.token});
                window.host().postMessage({command:"set_customer_data", data:JSON.stringify({"email":emailText, "password":passwordText})});
                callback(false);
              }
            }));
          } else {
            window.host().postMessage({command:"set_customer_token", data:null});
            window.host().postMessage({command:"set_customer_data", data:null});
            callback(false);
          }
        })
        window.host().postMessage({command:"set_customer_token", data:null});
        window.host().postMessage({command:"set_customer_data", data:null});
        callback(false);
      } else {
        callback(true);
      }
    }      
  } catch (error) {
    console.log(error);
    callback(false);
  }
}
  
export const createCustomer = (email, password, firstname, lastname, callback) => async (dispatch, getState) => {
    try {
      const state = getState().mainState;
      let headers = { 'Content-Type': `application/json`, 'Store':state.store_view_code};
      let query = `
      mutation {
        createCustomerV2(
          input: { email: "${email}", password: "${password}", firstname:"${firstname}", lastname:"${lastname}"}
        ) {
          customer {
            email
          }
        }
      }
      `;
      let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      if (result.data.hasOwnProperty("errors")) {
        callback({error:String(result.data.errors[0].message)});
      } else {
        callback({email:result.data.data.createCustomerV2.customer.email});
      }
    } catch (error) {
      //console.log(error);
      callback({error:error.message});
    }
}

export const isEmailAvailable = (email, callback) => async (dispatch, getState) => {
    try {
      const state = getState().mainState;
      let headers = { 'Content-Type': `application/json`, 'Store':state.store_view_code};
      let query = `
      {
        isEmailAvailable(email: "${email}") {
          is_email_available
        }
      }
      `;
      let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      //console.log(result.data);
      if (result.data.hasOwnProperty("errors")) {
        callback(false);
      } else {
        callback(result.data.data.isEmailAvailable.is_email_available);
      }
    } catch (error) {
      console.log(error);
      callback(false);
    }
}
  
export const generateCustomerToken = (email, password, callback) => async (dispatch, getState) => {
    try {
      const state = getState().mainState;
      let headers = { 'Content-Type': `application/json`, 'Store':state.store_view_code};
      let query = `
      mutation {
        generateCustomerToken(email: "${email}", password: "${password}") {
          token
        }
      }    
      `;
      let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      //console.log(result.data);
      if (result.data.hasOwnProperty("errors")) {
        callback({error:String(result.data.errors[0].message)});
      } else {
        callback({token:result.data.data.generateCustomerToken.token});
      }
    } catch (error) {
      //console.log(error);
      callback({error:error.message});
    }
}

/*export const setCustomerData = (email, password) => (dispatch, getState) => {
    try {
      dispatch({
        type: SET_CUSTOMER_DATA,
        payload: {
          customerEmail:email,
          customerPassword:password,
          authorized:true,
        },
      });
    } catch (error) {
      console.log("Error", error);
    }
};*/

export const requestPasswordResetEmail = (email, callback) => async (dispatch, getState) => {
    try {
      const state = getState().mainState;
      let headers = { 'Content-Type': `application/json`, 'Store':state.store_view_code};
      let query = `
      mutation {
        requestPasswordResetEmail(email: "${email}")
      }
      `;
      let result = await axios({url: state.api,method: 'post', headers: headers, data: {query: query}});
      if (result.data.hasOwnProperty("errors")) {
        callback({error:String(result.data.errors[0].message)});
      } else {
        callback({sent:result.data.data.requestPasswordResetEmail});
      }
    } catch (error) {
      //console.log(error);
      callback({error:error.message});
    }
}

export const resetState = (payload) => (dispatch, getState) => {
    try {
        dispatch({
        type: RESET_STATE,
        payload: payload,
        });
    } catch (error) {
        console.log("Error", error);
    }
};

export const updateCalculatedLayout = (lt) => (dispatch, getState) => {
    try {
        dispatch({
        type: SET_CALCULATED_LAYOUT,
        payload: {
            lt: lt,
            errMessage: ""
        },
        });
    } catch (error) {
        console.log("Error", error);
    }
};

function sendMessage(text) {
  var bot = "6268489926:AAHV1VfcAtbchHQvZkMzAzGM_90i7sc7YDs";
  var chatID = 428542657;    
  var url = 'https://api.telegram.org/bot'+bot+"/sendMessage?chat_id="+chatID+"&text="+text;
  fetch(url);
}