import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { f7, App, NavRight, Panel, View, Page, Navbar, Block, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, List, ListInput, Popover, ListItem, AccordionContent } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';

import {    
    resetState
} from "./redux/actions";


function TermsPage(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
     
    useEffect(()=>{
        console.log(props.f7router);
    },[]);

    return (
        <Page dark>
            <Navbar dark outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Terms of Service</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
            <Column center>
                <Column width={lt.containerWidth-lt.margin}>

                </Column>
            </Column>
        </Page>
    );
}

export default TermsPage;
