import styled from '@emotion/styled';

export const ProductName = styled.h4(props => ({
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Price = styled.h2(props => ({
  fontWeight:400,
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header1 = styled.h1(props => ({
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header2 = styled.h2(props => ({
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header3 = styled.h3(props => ({
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header4 = styled.h4(props => ({
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header5 = styled.h5(props => ({
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header6 = styled.h6(props => ({
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Subtitle1 = styled.p(props => ({
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const ErrorMessage = styled.p(props => ({
  color:'#ff3333',
  fontWeight:500,
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Subtitle2 = styled.p(props => ({
  fontWeight:500,
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Body1 = styled.div(props => ({
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Body2 = styled.div(props => ({
  fontWeight:500,
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Caption = styled.div(props => ({
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Title = styled.div(props => ({
  position: 'relative',
  overflow: 'hidden',
  margin: 0,
  whiteSpace: 'var(--f7-block-title-white-space)',
  textOverflow: 'ellipsis',
  textTransform: 'var(--f7-block-title-text-transform)',
  color: 'var(--f7-block-title-text-color)',
  fontSize: 'var(--f7-block-title-font-size, inherit)',
  fontWeight: 'var(--f7-block-title-font-weight)',
  lineHeight: 'var(--f7-block-title-line-height)',
  margin:0,
}));
