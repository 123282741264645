import "./App.css";
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { f7, NavRight, Block, Page, Navbar, Searchbar, Button, Popup, Link, Toolbar, Tabs, Tab, NavTitle, NavLeft, Icon, Badge, Preloader, Stepper, Card, CardHeader, CardContent, CardFooter, BlockTitle, List, ListInput, ListItem, Toggle, Progressbar } from 'framework7-react';
import { Row, Column, Stack, StackItem, Axis, Layer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, Title } from './Typography';
import 'framework7-icons';
import uuid from 'react-uuid';
import _ from 'lodash';
import styled from '@emotion/styled';
import { Image } from './Components';
import { calc, Debug } from './layout';

import { 
    resetState,
    getPapers
} from "./redux/actions";


function PapersPage(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const $ = window.$;
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState({sku:"", name:"", paper_thickness:""});
    const selectedPaper = stateObj.selectedPaper;

    //console.log(colors);

    useEffect(()=>{
        dispatch(getPapers(1, (result)=>{
            console.log(result);
            if (selectedPaper.sku !== "") {
                setSelected(selectedPaper);    
            } /*else {
                let s = {sku:result.data.products.items[0].sku, name:result.data.products.items[0].name, paper_thickness:result.data.products.items[0].paper_thickness};
                setSelected(s);
                dispatch(resetState({selectedPaper:s}));
            }*/
            setItems(result.data.products.items);
        }));
    },[])


    const handleChange = (e) => {
        let sku = $(e.target).closest("li").attr("data-sku");
        let i = _.findIndex(items, x => x.sku === sku);
        let name = "";
        let paper_thickness = "";
        if (i !== -1) {
            name = items[i].name;
            paper_thickness = items[i].paper_thickness;
        }
        dispatch(resetState({selectedPaper:{sku:sku, name:name, paper_thickness:paper_thickness}}));
        setSelected({sku:sku, name:name});
    }

    let colWidth = lt.bunch4;
    if (lt.max >= 8) {
        colWidth = lt.bunch8;
    }

    return (
        <Page id="home-page" dark >
            <Navbar dark outline>
                <NavLeft backLink="Back">
                </NavLeft>
                <NavTitle>Papers</NavTitle>
                <NavRight>
                </NavRight>
            </Navbar>
            <Column>
                <Column width={lt.containerWidth-lt.margin}>
                    <Column left={lt.margin} width={colWidth} >
                        <List dividersIos mediaList outlineIos strongIos>
                            {items.map((item)=>{
                                return(
                                    <ListItem key={item.sku} data-sku={item.sku} radio name="radio-papers" checked={item.sku === selected.sku ? true : false } onChange={handleChange}>
                                        <Row>
                                            <Column>
                                                <img src={item.image.url} width={80} height={80} style={{ borderRadius: '8px' }}></img>
                                            </Column>
                                            <Column left={lt.margin}>
                                                <Row><Subtitle2>{item.name}</Subtitle2></Row>
                                                <Row top={12}><div className="paper-short-description" dangerouslySetInnerHTML={{__html: item.short_description.html}} /></Row>
                                            </Column>
                                        </Row>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Column>
                </Column>
            </Column>
        </Page>
    );
}


export default PapersPage;
