import Home from './Home';
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
import { App, View } from 'framework7-react';
import LayoutPage from './LayoutPage';
import ColorsPage from './ColorsPage';
import FontsPage from './FontsPage';
import PapersPage from './PapersPage';
import AccountPage from './AccountPage';
import NewpasswordPage from './NewpasswordPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsPage from './TermsPage';

Framework7.use(Framework7React);

function Main() {

    const f7params = {
        name: 'Stickers',
        routes: [
        {
            path: '/',
            component: Home,
            options: {
                history: true,
                transition:'f7-parallax',
            },
        },
        {
            path: '/layout/',
            component: LayoutPage,
            options: {
                history: true,
                transition:'f7-parallax',
            },
        },
        {
            path: '/colors/',
            component: ColorsPage,
            options: {
                history: true,
                transition:'f7-parallax',
            },
        },
        {
            path: '/fonts/',
            component: FontsPage,
            options: {
                history: true,
                transition:'f7-parallax',
            },
        },
        {
            path: '/papers/',
            component: PapersPage,
            options: {
                history: true,
                transition:'f7-parallax',
            },
        },
        {
            path: '/account/',
            component: AccountPage,
            options: {
                history: true,
                transition:'f7-parallax',
            },
        },
        {
            path: '/newpassword/',
            component: NewpasswordPage,
            options: {
                history: true,
                transition:'f7-parallax',
            },
        },
        {
            path: '/privacy/',
            component: PrivacyPolicyPage,
            options: {
                history: true,
                transition:'f7-parallax',
            },
        },
        {
            path: '/terms/',
            component: TermsPage,
            options: {
                history: true,
                transition:'f7-parallax',
            },
        },
        ]
    };

  return (
      <App theme="ios" { ...f7params }>
          <View main url="/" browserHistoryRoot='/' browserHistory={true}></View>
      </App>
  );
}

export default Main;
